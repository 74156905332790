import FetchService from 'services/FetchService';

export default class TeamService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `teams?client_id=${clientId}`);
  }

  static async getTeam(clientId, id) {
    return FetchService.fetchData('GET', `teams/${id}?client_id=${clientId}`);
  }

  static upsertTeam(clientId, id, payload) {
    if (id) {
      return FetchService.fetchData('PUT', `teams/${id}?client_id=${clientId}`, payload);
    }

    return FetchService.fetchData('POST', `teams?client_id=${clientId}`, payload);
  }

  static async getQuestions(clientId) {
    return FetchService.fetchData('GET', `teams/questions?client_id=${clientId}`);
  }

  static async getTeamsFormQuestions(clientId) {
    return FetchService.fetchData('GET', `teams/form-questions?client_id=${clientId}`);
  }
}
