import React from 'react';
import hash from 'object-hash';

export default function Subheader(props) {
  const { description } = props;

  return (
    <div className="record-info">
      {description.map((record, index) => (
        <div key={hash({ index, record })}>{record}</div>
      ))}
    </div>
  );
}
