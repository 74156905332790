import React from 'react';
import { Form } from 'react-bootstrap';
import ReactDatePicker from 'react-date-picker';
import moment from 'moment-timezone';

export default function DatePicker(props) {
  const {
    value,
    question,
    callbackFunction,
    format,
  } = props;

  const onChange = (x) => {
    callbackFunction({
      target: {
        name: question.field,
        value: x && moment(x).format(format),
      },
    });
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>

      <ReactDatePicker
        onChange={onChange}
        value={value && moment(value, format).toDate()}
        maxDate={question.maxDate && moment(question.maxDate, format).toDate()}
        format={format || 'yyyy-MM-dd'}
        required={question.required}
      />

      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
