import React from 'react';
import { Form } from 'react-bootstrap';
import NumberPicker from 'react-widgets/NumberPicker';
import moment from 'moment-timezone';

export default function JulotaNumberPicker(props) {
  const {
    value,
    question,
    callbackFunction,
  } = props;

  const onChange = (newValue) => {
    callbackFunction({
      target: {
        name: question.field,
        value: newValue,
      },
    });
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>

      <NumberPicker
        onChange={onChange}
        value={value}
        min={0}
        max={100}
        placeholder={question.placeholder || question.label}
        disabled={question.disabled}
        required={question.required}
      />

      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
