import { React, useEffect } from 'react';
import { useParams } from 'react-router';
import CustomLink from 'components/CustomLink';
import Layout from './Layout';

export default function LayoutPage(props) {
  const { clientId, key } = useParams();

  useEffect(() => {});

  return (
    <div className="container-padding-40" style={{ overflowY: 'scroll' }}>
      <div className="row align-items-center mb-3">
        <div className="col">
          <h2>
            <CustomLink to={`/hubs/${clientId}/layouts`}>Layouts</CustomLink>
            <span style={{ padding: 10 }}>/</span>
            {key}
          </h2>
        </div>
      </div>

      <Layout layoutKey={key} />
    </div>
  );
}
