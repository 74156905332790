import React from 'react';
import { Button } from 'react-bootstrap';

export default function ConfirmToast(props) {
  const {
    config,
    confirm = () => {},
    cancel = () => {},
    closeToast = () => {},
    children,
  } = props;

  const onCancel = () => {
    closeToast();
    cancel();
  };

  const onConfirm = () => {
    closeToast();
    confirm();
  };

  return (
    <div className="confirm-toast">
      <h5>{config.title}</h5>
      <div className="toast-message">
        {config.message}
        {children}
      </div>
      <div className="toast-buttons">
        <Button variant="primary" onClick={onConfirm}>{config.confirmButton}</Button>
        <Button variant="outline-secondary" onClick={onCancel}>{config.cancelButton}</Button>
      </div>
    </div>
  );
}
