import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSessionHasWriteAccess } from 'redux/selectors';
import { useParams } from 'react-router-dom';
import '../styles/Profile.scss';
import { Button } from 'react-bootstrap';
import { PencilSquare, XSquare } from 'react-bootstrap-icons';
import Subheader from 'components/Subheader';
import JulotaForm from 'components/Forms/JulotaForm';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import ClientsService from '../services/ClientsService';

export default function Info() {
  const { clientId } = useParams();
  const [formDirty, setFormDirty] = useState(false);
  const [form, setForm] = useState({ data: {}, questions: [] });
  const [profile, setProfile] = useState();
  const hasWriteAccess = useSelector(selectSessionHasWriteAccess);

  const headerDescription = [
    `Created on ${GenericUtils.formatDateShort(form.data.createdt)} By ${form.data.createdby}`,
    form.data.updatedby && [...`Updated on ${GenericUtils.formatDateShort(form.data.updatedt)} By ${form.data.updatedby}`],
  ];

  const fetchClient = async () => {
    const res = await ClientsService.getClient(clientId);
    setForm({ ...res, viewMode: true });
    setProfile({ ...res.data });
  };

  const handleMessages = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { initialFormData, data } = message;

      try {
        await ClientsService.updateClient(
          data.id,
          GenericUtils.getObjectsDifference(initialFormData, data),
        );
        toast.success('Client updated successfully.');
        fetchClient();
        setForm({
          ...form,
          viewMode: true,
        });
        setFormDirty(false);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const toggleEditMode = () => {
    const setupForm = () => {
      setForm({
        id: form.id,
        questions: form.questions,
        viewModeDefaultColSize: form.viewModeDefaultColSize,
        data: { ...profile },
        viewMode: !form.viewMode,
      });
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={setupForm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      setupForm();
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);

  const getIcon = () => (form?.viewMode ? <PencilSquare /> : <XSquare />);
  const getLinkLabel = () => (form?.viewMode ? 'Edit' : 'Cancel');

  return (
    <div className="profile container-padding-40">
      <h2>Profile</h2>
      <hr />
      {hasWriteAccess && (
        <div className="float-right">
          <Button variant="link" size="sm" onClick={toggleEditMode}>
            { getIcon() }
            <span>{ getLinkLabel() }</span>
          </Button>
        </div>
      )}

      <div style={{ paddingBottom: 40 }}>
        <Subheader description={[
          `Created on ${GenericUtils.formatDateShort(form.data.createdt)} By ${form.data.createdby}`,
          form.data.updatedby && [...`Updated on ${GenericUtils.formatDateShort(form.data.updatedt)} By ${form.data.updatedby}`],
        ]}
        />
      </div>

      <div className="row">
        {form && (
          <JulotaForm
            inputData={form}
            callbackFunction={(message) => handleMessages(message)}
            formDirty={formDirty}
          />
        )}
      </div>
    </div>
  );
}
