import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CashCoin } from 'react-bootstrap-icons';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import SidePanel from 'components/Table/SidePanel';
import JulotaForm from 'components/Forms/JulotaForm';
import GenericUtils from 'utils/GenericUtils';
import ModulesService from '../services/ModulesService';

export default function Modules() {
  const gridRef = useRef();
  const { clientId } = useParams();
  const [modules, setModules] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [search, setSearch] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const [columnDefs] = useState([
    { field: 'name' },
    { field: 'category', width: 150 },
    { field: 'type' },
    {
      field: 'billable',
      headerClass: 'text-center',
      cellRenderer: (params) => (params.value ? 'Yes' : 'No'),
      cellStyle: { textAlign: 'center' },
      width: 75,
    },
    {
      field: 'status',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      width: 75,
      headerClass: 'text-center',
    },
  ]);

  const defaultColDef = {
    sortable: true,
    filter: false,
    suppressMovable: true,
    suppressMenu: true,
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const res = await ModulesService.getClientModules(clientId);
    setModules(res);
    setGridData(res);
  };

  const updateConfig = async (id, data) => {
    try {
      const params = id.split('_');

      await ModulesService.updateModuleConfig(params[1], {
        client_id: clientId,
        id,
        type: params[0],
        data,
      });

      setFormDirty(false);
      toast.success('Changes Saved!');
      fetchGridData();
    } catch (error) {
      toast.error(error);
    }
  };

  const validateAndInitialize = (tabIndex, forceHideToast) => {
    const onConfirm = async () => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      const row = tabIndex !== null ? {
        ...selectedRows[0],
        tabs: await ModulesService.getModuleConfig(clientId, selectedRows[0].module_key),
      } : null;

      setSelectedRow(row);
      setActiveTab(tabIndex);
      setFormDirty(false);
    };

    if (formDirty && !forceHideToast) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const onRowClicked = useCallback(() => {
    setLoading(true);
    validateAndInitialize(0);
    setLoading(false);
  }, [formDirty]);

  const submitForm = async (message) => {
    try {
      const { id, data, initialFormData } = message;

      if (selectedRow.billable && initialFormData.module_status === 'inactive' && data.module_status === 'active') {
        toast(
          <ConfirmToast
            config={{
              title: 'Paid Module Activation',
              message: 'Activating this module will result in additional costs for the customer',
              cancelButton: 'Cancel',
              confirmButton: 'Activate & Save',
            }}
            confirm={() => updateConfig(id, data)}
            cancel={() => {
              setFormDirty(false);
              setLoading(true);
              validateAndInitialize(0, true);
              setLoading(false);
            }}
          />,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false,
          },
        );
      } else {
        updateConfig(id, data);
      }

      setLoading(false);
      setFormDirty(false);
    } catch (errors) {
      if (Array.isArray(errors)) {
        toast.warning('There are errors in the form. Please try again.');
      } else {
        toast.error(errors);
      }
    }
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      submitForm(message);
    }
  };

  useEffect(async () => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(modules, search));
    } else {
      setGridData(modules);
    }
  }, [search]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Modules</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Modules..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="single"
          suppressContextMenu="true"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>
      {
        selectedRow
        && (
          <SidePanel close={() => validateAndInitialize(null)} confirmClosePrompt={formDirty} style={{ width: '600px' }} loading={loading}>
            <SidePanel.Header
              title={selectedRow.name}
              subtitle={selectedRow.module_key}
              style={{ borderBottom: 0 }}
            />
            <SidePanel.Content>
              {
                selectedRow.billable && (
                <div className="tag" style={{ float: 'right' }}>
                  <CashCoin />
                  &nbsp;
                  Paid
                </div>
                )
              }

              <div className="tabs-container" style={{ clear: 'both' }}>
                {
                  selectedRow.tabs.length === 1 && (
                    <JulotaForm
                      inputData={selectedRow.tabs[0]}
                      callbackFunction={(message) => handleCallback(message)}
                      formDirty={formDirty}
                    />
                  )
                }
                {
                  selectedRow.tabs.length > 1 && (
                    <Tabs
                      onSelect={validateAndInitialize}
                      activeKey={activeTab}
                      defaultActiveKey={0}
                      mountOnEnter
                      unmountOnExit
                    >
                      {
                        selectedRow.tabs.map((form, index) => form.data && (
                          <Tab eventKey={index} title={form.name} key={form.id}>
                            <JulotaForm
                              inputData={form}
                              callbackFunction={(message) => handleCallback(message)}
                              formDirty={formDirty}
                            />
                          </Tab>
                        ))
                      }
                    </Tabs>
                  )
                }
              </div>
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
