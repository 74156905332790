import React, { useEffect, useState } from 'react';
import GenericUtils from 'utils/GenericUtils';
import hash from 'object-hash';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import { FileEarmark } from 'react-bootstrap-icons';
import { ListGroup, Modal } from 'react-bootstrap';
import RunHistoryItem from './RunHistoryItem';

export default function RunHistory({ data, summary, type }) {
  const [runHistoryByDate, setRunHistoryByDate] = useState({});
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const splitHistory = {};
    data.forEach((h) => {
      const date = GenericUtils.formatDateShort(h[type === 'import' ? 'date_received' : 'createdt']);

      if (!splitHistory[date]) {
        splitHistory[date] = [h];
      } else {
        splitHistory[date] = [...splitHistory[date], h];
      }
    });

    setRunHistoryByDate(splitHistory);
  }, []);

  return (
    <>
      {
        summary && (
          <div className="events-process-overview">
            <h6><b>Events Processed Overview (Last 12 Months)</b></h6>
            <table style={{ textAlign: 'center', marginTop: 20, width: '100%' }}>
              <tr>
                {summary.map((event) => <th>{event.timeframe_display}</th>)}
              </tr>
              <tr>
                {summary.map((event) => <td>{event.event_count}</td>)}
              </tr>
            </table>
          </div>
        )
      }
      <div className="run-history">
        {
          Object.keys(runHistoryByDate).map((date) => (
            <>
              <div className="interfaces-date-divider">
                <div className="divline" />
                <div className="date">
                  {date}
                </div>
              </div>
              <ListGroup variant="flush">
                {runHistoryByDate[date].map((h) => (
                  <RunHistoryItem
                    key={hash(h)}
                    icon={type === 'import' && <FileEarmark className="file-icon" />}
                    title={h.filename || h.run_id}
                    status={h.status}
                    statusReason={h.status_reason}
                    createdt={h.createdt || h.date_received}
                    updatedt={h.updatedt || h.process_date}
                    processTime={h.process_time}
                    metadata={h.metadata}
                    onDetailsClick={() => setMetadata({
                      filename: h.filename || h.run_id,
                      data: h.metadata,
                    })}
                  />
                ))}
              </ListGroup>
            </>
          ))
        }
        {metadata && (
          <Modal
            size="xl"
            centered
            show={!!metadata}
            onHide={() => setMetadata(null)}
          >
            <Modal.Header closeButton>
              {metadata.filename}
            </Modal.Header>
            <Modal.Body>
              <CodeMirror
                value={JSON.stringify(metadata.data, null, 2)}
                height="auto"
                width="100%"
                theme="dark"
                extensions={[json()]}
                readOnly
              />
            </Modal.Body>
          </Modal>
        )}

      </div>
    </>
  );
}
