import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';

export default function DefaultTagCellRenderer(props: ICellRendererParams) {
  const { data } = props;

  return (
    <div>
      {data.display}
      { data?.is_default ? <span className="badge badge-pill badge-info">Default</span> : null }
    </div>
  );
}
