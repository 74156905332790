/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';

export default class OrganizationService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `organizations?client_id=${clientId}`);
  }

  static async getOrganization(clientId, key) {
    return FetchService.fetchData('GET', `organizations/${key}?client_id=${clientId}`);
  }

  static upsertOrganization(clientId, key, payload) {
    if (key) {
      return FetchService.fetchData('PUT', `organizations/${key}?client_id=${clientId}`, payload);
    }

    return FetchService.fetchData('POST', `organizations?client_id=${clientId}`, payload);
  }

  static deleteOrganization(key) {
    return FetchService.fetchData('DELETE', `organizations/${key}`);
  }

  static async getOrganizationsForm(data) {
    const isPrimary = data && data?.access_level === 'primary';
    const isPartner = data && data?.access_level === 'partner';
    const isResource = data && data?.access_level === 'resource';

    return [
      {
        label: 'Status',
        field: 'status',
        type: 'DROPDOWN',
        required: true,
        disabled: isPrimary,
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      {
        label: 'Name',
        field: 'name',
        type: 'TEXT',
        required: true,
      },
      {
        label: 'Description',
        field: 'description',
        type: 'TEXTAREA',
        required: true,
      },
      {
        label: 'Access Level',
        field: 'access_level',
        type: 'DROPDOWN',
        required: true,
        disabled: isPrimary,
        options: [
          { label: 'Primary', value: 'primary', isDisabled: true },
          { label: 'Internal', value: 'internal', isDisabled: true },
          { label: 'Hub-to-Hub', value: 'hub-to-hub', isDisabled: true },
          { label: 'Partner', value: 'partner' },
          { label: 'Resource', value: 'resource' },
        ],
      },
      {
        label: 'Patient Access',
        field: 'patient_access',
        type: 'DROPDOWN',
        required: true,
        disabled: isPrimary,
        options: [
          { label: 'Full', value: 'full', isDisabled: isResource },
          { label: 'Limited', value: 'limited', isDisabled: isPrimary },
          { label: 'None', value: 'none', isDisabled: isPrimary || isPartner },
        ],
      },
      {
        label: 'Type',
        field: 'type',
        type: 'TEXT',
      },
    ];
  }
}
