import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useBlocker } from 'hooks/useBlocker';
import { AgGridReact } from 'ag-grid-react';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SearchBar from 'components/Search/SearchBar';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';
import Subheader from 'components/Subheader';
import JulotaFormNew from 'components/Forms/JulotaFormNew';
import SidePanel from 'components/Table/SidePanel';

import ConfirmToast from 'modules/core/components/ConfirmToast';
import GenericUtils from 'utils/GenericUtils';
import GroupService from '../services/GroupService';

export default function Groups() {
  const { clientId } = useParams();
  const [groups, setGroups] = useState('');
  const [questions, setQuestions] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [panel, setPanel] = useState();
  const [loading, setLoading] = useState(false);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name / Description',
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }) => (
        <DescriptionCellRenderer name={data.name} description={data.description} />
      ),
      sortable: true,
    },
    { headerName: 'Description', field: 'description' },
    { headerName: 'Emails', field: 'emails' },
    { headerName: 'Created By', field: 'createdby', width: 100 },
    {
      headerName: 'Create Date',
      field: 'createdt',
      width: 150,
      cellRenderer: (parameters) => GenericUtils.formatDateShort(parameters.value),
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const blocker = useCallback((tx) => {
    checkUnsavedChanges(() => {
      tx.retry();
      setPanel(null);
      setFormDirty(false);
    });
  }, [formDirty]);

  useBlocker(blocker, formDirty);

  const onRowClicked = useCallback(async (params) => {
    checkUnsavedChanges(async () => {
      const { id } = params.api.getSelectedRows()[0];
      const group = await GroupService.getGroup(clientId, id);

      setPanel({
        key: group.key,
        title: group.name,
        subtitle: group.key,
        questions,
        data: {
          ...group,
          users: group.users || [],
        },
      });
      setFormDirty(false);
    });
  }, [formDirty, questions]);

  const createGroup = async () => {
    setPanel({
      key: 'new-group',
      title: 'Add Group',
      questions,
      data: {
        status: 'active',
        users: [],
      },
      submitText: 'Add Group',
    });
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const setupComponent = async () => {
    const qs = await GroupService.getQuestions(clientId);
    setQuestions(qs);

    const res = await GroupService.getAll(clientId);
    setGroups(res);
    setGridData(res);
  };

  useEffect(() => {
    setupComponent();
  }, []);

  const onFormChange = async (params) => {
    setFormDirty(params.isDirty);
    setPanel({
      ...panel,
      questions,
    });
  };

  const onFormSubmit = async (params) => {
    try {
      const { data } = params;
      setLoading(true);

      const invalidName = groups
        .filter((t) => t.id !== data.id?.toString())
        .map((t) => t.name)
        .filter((tn) => tn.trim().toLowerCase() === data.name.trim().toLowerCase());

      if (invalidName.length > 0) {
        toast.error('Existing Group with that name. Name must be unique');
      } else {
        await GroupService.upsertGroup(clientId, data.id, {
          ...data,
          emails: data.emails?.replace(/ /g, '').split(','),
          users: data.users?.map((u) => u.key),
        });
        toast.success('Changes Saved!');
        setFormDirty(false);
        setupComponent();
      }
    } catch (error) {
      toast.error(error);
      setFormDirty(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGridData(searchText.length > 0 ? GenericUtils.search(groups, searchText) : groups);
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Groups</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar placeholder="Search Groups..." onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-3 text-align-right">
          <Button variant="link" onClick={createGroup}>
            Create Group
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
          }}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaFormNew
                questions={panel.questions}
                data={panel.data}
                isDirty={formDirty}
                isDisabled={panel.disabled}
                submitText={panel.submitText}
                onFormChange={onFormChange}
                onFormSubmit={onFormSubmit}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
