import React, { useState, useEffect } from 'react';
import hash from 'object-hash';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Plus, Trash, PencilSquare } from 'react-bootstrap-icons';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { v4 as uuidv4 } from 'uuid';
import EditableTable from 'components/Forms/EditableTable';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import MultiSelect from 'components/Forms/MultiSelect';
import GenericUtils from 'utils/GenericUtils';

export default function RulesObject(props) {
  const { question, value, callbackFunction } = props;
  const [show, setShow] = useState(false);
  const [rules, setRules] = useState(value || []);
  const [newRule, setNewRule] = useState({
    // uid: null,
    scope: [],
    conditions: null,
  });

  const addingRule = () => {
    const preSelectedScope = question.scope?.options.length === 1
      ? [question.scope.options[0].value]
      : [];

    setNewRule({
      uid: uuidv4(),
      scope: preSelectedScope,
      conditions: null,
    });
    setShow(true);
  };

  const saveChanges = () => {
    const newItems = rules.slice();
    const index = newItems.findIndex((item) => item.uid === newRule.uid);

    if (index !== -1) {
      newItems.splice(index, 1, newRule);
    } else {
      newItems.push(newRule);
    }

    setRules(newItems);
    setShow(false);

    callbackFunction({
      target: {
        name: question.field,
        value: newItems,
      },
    });
  };

  const editRule = (data) => {
    const edittingRule = value.find((e) => e.uid === data.uid);
    setNewRule(edittingRule);
    setShow(true);
  };

  const deleteRule = (uid) => {
    const deleteRuleConditions = () => {
      const newItems = rules.slice();
      const index = rules.findIndex((item) => item.uid === uid);

      if (index !== -1) {
        newItems.splice(index, 1);
      }

      setRules(newItems);

      callbackFunction({
        target: {
          name: question.field,
          value: newItems,
        },
      });
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Delete Rules Condition?',
          message: 'This action will remove the rules condition.',
          cancelButton: 'Cancel',
          confirmButton: 'Delete',
        }}
        confirm={deleteRuleConditions}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const handleConditionTableEdits = (data) => {
    newRule[data.target.name] = data.target.value;
    setNewRule(newRule);
  };

  const onChangeFunction = (e) => {
    setNewRule({
      ...newRule,
      scope: e.target.value,
    });
  };

  const contextSelector = (params) => {
    if (question.options) {
      return {
        component: 'agSelectCellEditor',
        params: {
          values: question.options?.map((i) => i.type),
        },
      };
    }
    return undefined;
  };

  const factsSelector = (params) => {
    const context = question.options?.find((c) => c.type === params.data.context);

    return {
      component: 'agSelectCellEditor',
      params: {
        values: context.facts.map((f) => f.element),
      },
    };
  };

  const operatorSelector = (params) => {
    if (params.data.fact) {
      const context = question.options?.find((c) => c.type === params.data.context);
      const contextElement = context.facts.find((f) => f.element === params.data.fact);

      return {
        component: 'agSelectCellEditor',
        params: {
          values: contextElement.operators,
        },
      };
    }
    return undefined;
  };

  const valueSelector = (params) => {
    if (params.data.fact) {
      const context = question.options?.find((c) => c.type === params.data.context);
      const contextElement = context.facts.find((f) => f.element === params.data.fact);

      // TODO: We need to have another cell editor that allows for typing value
      if (contextElement.values) {
        return {
          component: 'agSelectCellEditor',
          params: {
            values: contextElement.values,
          },
        };
      }
      return undefined;
    }
    return undefined;
  };

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: '1000px', width: '1000px' }}>
      <Popover.Header as="h3">{question.label}</Popover.Header>
      <Popover.Body>
        <div>
          {
            question.scope && (
              <MultiSelect
                question={question.scope}
                value={newRule.scope || []}
                callbackFunction={onChangeFunction}
              />
            )
          }
          <EditableTable
            value={newRule.conditions || []}
            question={{
              label: 'Conditions',
              field: 'conditions',
              sizeColumnsToFit: true,
              objectStructure: {
                context: null,
                fact: null,
                operator: null,
                value: null,
              },
              columnDefs: [
                { field: 'context', width: 140, cellEditorSelector: contextSelector },
                { field: 'fact', width: 600, cellEditorSelector: factsSelector },
                { field: 'operator', width: 120, cellEditorSelector: operatorSelector },
                { field: 'value', cellEditorSelector: valueSelector },
              ],
            }}
            callbackFunction={handleConditionTableEdits}
          />
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button variant="primary" size="sm" onClick={() => saveChanges()}>
            Save Changes
          </Button>
          <Button variant="outline-danger" size="sm" onClick={() => setShow(false)} style={{ marginLeft: 5 }}>
            Cancel
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <div className="text-muted" style={{ fontSize: '12px', marginBottom: '10px' }}>
        {question.description}
      </div>

      {
        rules && rules.map((rule, index) => (
          <div className="rule-box" key={hash({ rule, index })} style={{ background: question.disabled ? '#e9ecef' : 'white' }}>
            {
              !question.disabled && (
                <div className="float-right text-align-right">
                  <Button variant="link" size="sm" onClick={() => editRule(rule)} style={{ fontSize: 11 }}>
                    <PencilSquare />
                    <span>Edit</span>
                  </Button>
                  |
                  <Button variant="link" size="sm" onClick={() => deleteRule(rule.uid)} style={{ fontSize: 11 }}>
                    <Trash />
                    <span>Delete</span>
                  </Button>
                </div>
              )
            }
            <div>
              {
                question.scope && (
                  <div>
                    <b>{`${question.scope.label}:`}</b>
                    {
                      rule.scope && (
                        <div style={{ marginBottom: 10 }}>{ rule.scope.join(', ') }</div>
                      )
                    }
                  </div>
                )
              }
              <b>Conditions: </b>
              <ul>
                { rule.conditions?.map((c) => (
                  <li key={hash(c)}>
                    <u>{c.context}</u>
                    {' : '}
                    {`${c.fact} ${c.operator} ${c.value}` }
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))
      }

      {
        !question.disabled && (
          <OverlayTrigger show={show} placement="right" overlay={popover}>
            <Button variant="link" size="sm" onClick={() => { addingRule(); }}>
              <Plus />
              Add Rule
            </Button>
          </OverlayTrigger>
        )
      }
    </Form.Group>
  );
}
