import moment from 'moment-timezone';

export default class ClientUsagesHelper {
  static mapClientData = (data, type) => {
    const maping = {};
    const mt = {};
    data.forEach((item, j) => {
      const arr = [];
      if (type === 'actives') {
        arr.push({
          id: j,
          month: item.month,
          active: item.active_patients,
        });
        if (!Object.prototype.hasOwnProperty.call(maping, item.year)) {
          maping[item.year] = arr;
        } else {
          maping[item.year] = maping[item.year].concat(arr);
        }
      } else {
        arr.push({
          month: moment.months(item.month - 1),
          id: item.julota_id,
          entity: item.entity,
          event: item.event_user,
          description: item.event_description,
          did: j,
        });
        if (!Object.prototype.hasOwnProperty.call(mt, item.month)) {
          mt[item.month] = arr;
        } else {
          mt[item.month] = mt[item.month].concat(arr);
        }
        maping[item.year] = JSON.parse(JSON.stringify(mt));
      }
    });

    return maping;
  };

  static usageSearch = (data, find) => data.filter(
    (item) => item.entity.toLowerCase().includes(find)
        || item.event_user.toLowerCase().includes(find)
        || item.event_description.toLowerCase().includes(find)
        || item.julota_id.toLowerCase().includes(find),
  );
}
