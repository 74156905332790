/* AUTH ACTIONS */
export const setAuth = (authToken) => ({
  type: 'REQUEST_NEW_SESSION',
  payload: {
    ...authToken.verification,
    changepass: authToken.force_change_password,
  },
});

export const requestTwoFactor = () => ({
  type: 'REQUEST_2FA_SESSION',
  payload: true,
});

/* SESSION ACTIONS */
export const setSession = (session) => ({
  type: 'SET_SESSION',
  payload: session,
});

/* SELECTED CLIENT ACTIONS */
export const setClient = (client) => ({
  type: 'SET_CLIENT',
  payload: client,
});
