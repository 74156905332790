import React from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import hash from 'object-hash';

export default function Toggle(props) {
  const {
    question,
    value,
    callbackFunction,
  } = props;

  const position = question.position !== undefined ? question.position : 'end';

  const onChange = (e) => {
    callbackFunction({
      target: {
        name: question.field,
        value: e,
      },
    });
  };

  return (
    <div className="toggle" key={hash(question)} style={{ flexDirection: position === 'end' ? 'row' : 'row-reverse' }}>
      <div className="display" style={{ marginRight: position === 'end' ? 20 : 0 }}>
        <div className="label">{question.label}</div>
        <small>{question.description}</small>
      </div>
      <div style={{ marginRight: position === 'front' ? 10 : 0 }}>
        <BootstrapSwitchButton
          size="sm"
          onlabel={question.options?.find((opt) => opt.value === true)?.label}
          offlabel={question.options?.find((opt) => opt.value === false)?.label}
          checked={value}
          onChange={onChange}
          disabled={question.disabled}
          onstyle={question.disabled && 'secondary'}
        />
      </div>
    </div>
  );
}
