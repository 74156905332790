import FetchService from 'services/FetchService';

export default class ClientUsageService {
  static getUsages(months) {
    return FetchService.fetchData('GET', `client-usage?months=${months}`);
  }

  static getClientUsages(id) {
    return FetchService.fetchData('GET', `client-usage/${id}`);
  }

  static getClientUsagesDetails(id) {
    return FetchService.fetchData('GET', `client-usage/${id}/details`);
  }
}
