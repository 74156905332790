import React from 'react';
import { Form } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';

export default function InputMask(props) {
  const {
    question,
    value,
    callbackFunction,
  } = props;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <ReactInputMask
        value={value}
        required={question.required}
        disabled={question.disabled}
        mask={question.mask}
        name={question.field}
        maskChar={question.maskChar}
        formatChars={question.formatChars}
        placeholder={question.placeholder || question.label}
        alwaysShowMask={question.alwaysShowMask}
        beforeMaskedValueChange={question.beforeMaskedValueChange}
        onChange={callbackFunction}
      >
        {(inputProps) => <Form.Control disabled={question.disabled} {...inputProps} />}
      </ReactInputMask>
      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
