import FetchService from 'services/FetchService';

export default class DataExportsService {
  static getClientDataExports(clientId) {
    return FetchService.fetchData('GET', `exports?client_id=${clientId}`);
  }

  static getDataExportDetails(clientId, key) {
    return FetchService.fetchData('GET', `exports/details?client_id=${clientId}&key=${key}`);
  }

  static updateOrCreateClientDataExport(clientId, dataExport) {
    return FetchService.fetchData('POST', 'exports', { clientId, ...dataExport });
  }
}
