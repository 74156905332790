import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import authReducers from './reducers/AuthCoreReducers';
import clientReducer from './reducers/ClientReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedClientReducer = persistReducer(persistConfig, clientReducer);

export const store = configureStore({
  reducer: {
    coreModule: authReducers,
    selectedClient: persistedClientReducer,
  },
});

export const persistor = persistStore(store);
