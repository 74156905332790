import { React } from 'react';
import Card from 'react-bootstrap/Card';

export default function CustomLink({
  children,
  title,
  subtitle,
  description,
  props,
}) {
  return (
    <Card
      backgroundColor="light"
      style={{
        width: 'auto',
        minWidth: '18rem',
      }}
      {...props}
    >
      <Card.Body>
        { title && <Card.Title>{title}</Card.Title> }
        { subtitle && <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle> }
        {children}
        <Card.Text>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
