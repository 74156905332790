import React from 'react';

export default function ReachFormControl(props) {
  const { question } = props;

  return (
    <div style={{ display: 'inline' }}>
      {question.before_text && (
        <span className="reach-form-label">
          {question.before_text}
        </span>
      )}

      <span
        className={question.required ? 'reach-app-input required' : 'reach-app-input optional'}
        role="textbox"
        contentEditable
        data-placeholder={question.placeholder}
      >
        {question.value}
      </span>
    </div>
  );
}
