import {
  React,
  useState,
  useEffect,
  useCallback,
} from 'react';
import cronstrue from 'cronstrue';
import GenericUtils from 'utils/GenericUtils';
import hash from 'object-hash';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Tab, Tabs } from 'react-bootstrap';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import JulotaForm from 'components/Forms/JulotaForm';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';
import DataExportsService from '../services/DataExportsService';

export default function DataExports() {
  const { clientId } = useParams();
  const [dataExports, setDataExports] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [tabs, setTabs] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [columnDefs] = useState([
    {
      field: 'name',
      sortable: true,
      width: 100,
    },
    {
      field: 'key',
      sortable: true,
      width: 75,
    },
    {
      field: 'display',
      headerName: 'Description',
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }) => (
        <DescriptionCellRenderer name={data.display} description={data.description} />
      ),
    },
    {
      field: 'category',
      sortable: true,
      width: 60,
    },
    {
      field: 'is_customized',
      headerName: 'Is Customized',
      sortable: true,
      width: 50,
      cellRenderer: (params) => (params.value ? 'Yes' : 'No'),
    },
    {
      field: 'formats',
      sortable: true,
      width: 40,
    },
    {
      field: 'active',
      headerName: 'Status',
      sortable: true,
      width: 40,
      cellRenderer: (params) => (params.value ? 'Active' : 'Inactive'),
      cellStyle: (params) => ({ ...GenericUtils.statusCellStyle(params), textAlign: 'left' }),
    },
  ]);

  const fetchGridData = async () => {
    const res = await DataExportsService.getClientDataExports(clientId);
    setDataExports(res);
    setGridData(res);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const handleSelectTab = (eventKey) => {
    const onConfirm = async () => {
      setActiveTab(parseInt(eventKey, 10));
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const onRowClicked = useCallback((params) => {
    const onConfirm = async () => {
      setLoading(true);
      const { key, display, description } = params.data;
      const dataExport = await DataExportsService.getDataExportDetails(clientId, key);

      setSelectedRow({ key, display, description });
      setTabs(dataExport);
      setFormDirty(false);
      setLoading(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setTabs(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      setLoading(true);

      if (data.interval === 'custom') {
        cronstrue.toString(data.custom_interval);
      } else {
        delete data.custom_interval;
      }

      try {
        await DataExportsService.updateOrCreateClientDataExport(clientId, data);

        toast.success('Changes Saved!');
        setFormDirty(false);
        fetchGridData();
      } catch (error) {
        toast.error(error);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(dataExports, searchText));
    } else {
      setGridData(dataExports);
    }
  }, [searchText]);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Data Exports</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Data Exports..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        tabs && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 850 }}
          >
            <SidePanel.Header
              title={selectedRow.key}
              subtitle={selectedRow.description}
            />

            <SidePanel.Content>
              <div className="tabs-container">
                <Tabs
                  onSelect={handleSelectTab}
                  activeKey={activeTab}
                  defaultActiveKey={0}
                  mountOnEnter
                  unmountOnExit
                  style={{ marginTop: 20 }}
                >
                  {tabs?.map((tab, index) => (
                    <Tab
                      eventKey={index}
                      title={tab.title}
                      key={hash(tab.title)}
                    >
                      <JulotaForm
                        inputData={tab.form}
                        callbackFunction={(message) => handleCallback(message)}
                        formDirty={formDirty}
                      />
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
