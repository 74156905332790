import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

export default function MultiSelect(props) {
  const {
    question,
    value,
    formData,
    className,
    callbackFunction,
  } = props;

  const [selectedValue, setSelectedValue] = useState([]);

  const isMulti = question.isMulti !== undefined ? question.isMulti : true;
  const isClearable = question.isClearable !== undefined ? question.isClearable : true;
  const isCreatable = question.isCreatable !== undefined ? question.isCreatable : false;

  const onChange = (params) => {
    const formValue = Array.isArray(params) && params.length > 0
      ? params.map((v) => (v.value ? v.value : v))
      : params?.value;

    callbackFunction({
      target: {
        name: question.field,
        value: formValue,
      },
    });
  };

  const validateOption = (option, f) => {
    const conditionTests = option.conditions.map((c) => {
      switch (c.operator) {
        case 'ARRAY_SIZE_GREATER_THAN':
          return f[c.field]?.length > c.value;

        default: return f[c.field] === c.value;
      }
    });
    return !conditionTests.includes(false);
  };

  const renderElement = () => {
    const filteredOptions = question.options?.filter((o) => o?.conditions === undefined
      || validateOption(o, formData));

    const elementProperties = {
      isMulti,
      isClearable,
      options: filteredOptions,
      value: selectedValue || null,
      onChange,
      isDisabled: question.disabled,
      placeholder: question.placeholder,
    };

    return isCreatable
      ? <Creatable {...elementProperties} />
      : <Select {...elementProperties} />;
  };

  useEffect(() => {
    let matchedOptions = question.options?.filter((o) => (
      Array.isArray(value) ? value.includes(o.value) : o.value === value
    ));

    if (value && matchedOptions?.length === 0 && question.isCreatable) {
      matchedOptions = [{ label: value, value, __isNew__: true }];
    }

    setSelectedValue(matchedOptions);
  }, [value]);

  return (
    <Form.Group className={className || 'mb-3'}>
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      { renderElement() }
      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
