/* eslint-disable eqeqeq */
import ConfirmToast from 'modules/core/components/ConfirmToast';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

export default class GenericUtils {
  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static setMonthDate(date, type) {
    return date ? moment().startOf('year').add(date, 'months').format(type) : '';
  }

  static formatDateShort(date) {
    return date ? moment(date).format('MMMM Do, YYYY') : '';
  }

  static formatDateLong(date) {
    return date ? moment(date).format('MMMM Do YYYY, h:mm:ss a') : '';
  }

  static formatDateLongAtTimezone(date, timezone) {
    return date ? moment.utc(date).tz(timezone).format('MM/DD/YYYY HH:mm:ss a') : '';
  }

  static getObjectsDifference(originalObject, newObject) {
    const difference = {};

    Object.keys(newObject).forEach((key) => {
      if (Array.isArray(newObject[key]) || typeof newObject[key] === 'object') {
        if (JSON.stringify(originalObject[key]) !== JSON.stringify(newObject[key])) {
          difference[key] = newObject[key];
        }
      } else if (newObject[key] != originalObject[key]) {
        difference[key] = newObject[key];
      }
    });

    return difference;
  }

  static areObjectsEqual(obj1, obj2) {
    let result = true;
    Object.keys(obj1).forEach((key) => {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
        result = false;
      }
    });

    return result;
  }

  static getDeepValue = (obj, path) => {
    const paths = path.split('.');
    let curr = { ...obj };

    // eslint-disable-next-line no-restricted-syntax
    for (const p of paths) {
      if (curr[p] === undefined) {
        return undefined;
      }
      curr = curr[p];
    }

    return curr;
  };

  static confirmToastOptions = {
    autoClose: false,
    closeOnClick: false,
    closeButton: false,
    draggable: false,
    toastId: 'confirm_toast',
  };

  static discardChangesToastConfig = {
    title: 'Unsaved Changes',
    message: 'Are you sure you want to discard these changes? They will be lost.',
    cancelButton: 'Continue Editing',
    confirmButton: 'Discard',
  };

  static usernameAlreadyExistsToastConfig = {
    title: 'Invalid Username',
    message: 'That username is already taken. Please select a different one.',
    cancelButton: 'OK',
  };

  static resetPasswordToastConfig = {
    title: 'Reset Password',
    message: 'Are you sure you want to reset this password and send a reset password email?',
    cancelButton: 'Cancel',
    confirmButton: 'Reset',
  };

  static confirmToast(
    // condition = false,
    onConfirm = () => {},
    config = {
    },
  ) {
    toast(
      // eslint-disable-next-line react/react-in-jsx-scope
      <ConfirmToast
        config={config}
        confirm={onConfirm}
      />,
    );
  }

  static search(data, text) {
    return data?.filter((obj) => (
      Object.values(obj).some((val) => (
        val?.toString().toLowerCase().includes(text.toLowerCase())
      ))
    ));
  }

  static statusCellStyle(params) {
    let resultColor;
    switch (params.value) {
      case true:
      case 'active':
        resultColor = 'var(--color-active)';
        break;
      case 'inactive':
        resultColor = 'var(--color-inactive)';
        break;
      case false:
      case 'archived':
        resultColor = 'var(--color-archived)';
        break;
      case 'deleted':
        resultColor = 'var(--color-deleted)';
        break;
      default: resultColor = 'var(--color-black)';
    }

    return {
      color: resultColor,
      textAlign: 'center',
      textTransform: 'capitalize',
    };
  }

  static formatIntervalIntoHumanReadable(interval) {
    return interval.toString().length <= 5 ? `${interval / 1000} seconds` : moment.duration(interval).humanize();
  }
}
