import { React } from 'react';

export default function NotFound() {
  const notFoundContainerStyle = {
    textAlign: 'center', marginTop: '40px', fontWeight: '200', fontSize: '24px', flex: 1,
  };
  return (
    <div style={notFoundContainerStyle}>
      <h1>Oops!</h1>
      <p>The page you are looking for doesn&apos;t exist</p>
      <img src="error-pages/page-not-found.svg" alt="not-found" />
    </div>
  );
}
