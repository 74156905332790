/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';

export default class ClientsService {
  static getClients() {
    return FetchService.fetchData('GET', 'clients/all');
  }

  static async getClient(clientId) {
    const url = clientId
      ? `clients/${clientId}`
      : 'clients';

    const form = await FetchService.fetchData('GET', url);

    form.questions.find((q) => q.field === 'active').formatter = (params) => (
      params
        ? <p style={{ color: 'var(--color-active)' }}>Active</p>
        : <p style={{ color: 'var(--color-deleted)' }}>Inactive</p>);

    return form;
  }

  static updateClient(clientId, content) {
    return FetchService.fetchData('PATCH', `clients/${clientId}`, content);
  }

  static postClient(content) {
    return FetchService.fetchData('POST', 'clients', content);
  }

  static createClientAppVersion(clientId, name, description) {
    return FetchService.fetchData('POST', `clients/${clientId}/version`, { name, description });
  }

  static publishClientAppVersion(clientId, versionId, name, description) {
    return FetchService.fetchData('PUT', `clients/${clientId}/version/${versionId}`, { name, description });
  }

  static discardClientAppVersion(clientId, versionId) {
    return FetchService.fetchData('DELETE', `clients/${clientId}/version/${versionId}`);
  }

  static getVersions(clientId) {
    return FetchService.fetchData('GET', `clients/${clientId}/versions`);
  }

  static getClientOrgs(clientId) {
    return FetchService.fetchData('GET', 'clients/organizations', null, {
      client_id: clientId,
    });
  }
}
