import React from 'react';

export default function DescriptionCellRenderer(props) {
  const { name, description } = props;

  return (
    <div style={{ lineHeight: '20px', fontSize: '12px', paddingTop: '10px' }}>
      <div style={{ textTransform: 'uppercase', color: 'var(--color-2)', fontWeight: '600' }}>{name}</div>
      <div>{description}</div>
    </div>
  );
}
