import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class FetchService {
  static async fetchData(method, url, body, params) {
    const GENERIC_ERROR_CODES = {
      500: 'Something went wrong',
      504: 'Timeout error, it took too long',
      404: 'Feature not yet implemented',
      423: 'Locked Account',
    };

    try {
      const tpAccessToken = sessionStorage.getItem('tp-access-token');
      const response = await axios.request({
        method,
        params,
        url: `/api/${url}`,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
          'x-request-id': uuidv4(),
          ...(tpAccessToken && { 'tp-access-token': tpAccessToken }),
        },
        ...(body && { data: JSON.stringify(body) }),
      });

      return response.data;
    } catch (error) {
      const { status } = error.response;

      const errorMessage = GENERIC_ERROR_CODES[status];
      if (errorMessage) {
        toast.error(errorMessage);
        throw error;
      } else {
        throw error.response.data;
      }
    }
  }
}
