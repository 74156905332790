import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Key } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { selectSessionIsSuspended, selectSessionIsLocked } from 'redux/selectors';
import FormValidation from '../../../utils/FormValidation';
import './UnlockModal.scss';

function UnlockModal(props) {
  const {
    error,
    onSignout,
    onUnlocked,
  } = props;

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const isSessionSuspended = useSelector(selectSessionIsSuspended);
  const isSessionLocked = useSelector(selectSessionIsLocked);

  const handleUnlock = async (e) => {
    e.preventDefault();
    setLoading(true);
    onUnlocked(passcode);
    setPasscode('');
  };

  useEffect(() => {
    const valid = (!FormValidation.validateIsql(passcode) && FormValidation.onlyNumbers(passcode))
      || passcode.length === 0;

    setIsValid(valid);
  }, [passcode]);

  useEffect(() => {
    if (isSessionSuspended && isSessionLocked) {
      setPasscode('');
      setShow(true);
    } else {
      setShow(false);
    }
    if (showAlert) {
      setShowAlert(false);
    }
  }, [isSessionSuspended, isSessionLocked]);

  useEffect(() => {
    setShowAlert(error);
  }, [error]);

  const handleChange = (e) => {
    setPasscode(e.target.value);
    if (isValid) {
      setLoading(false);
      setShowAlert(false);
    }
  };

  return (
    <Modal className="frosty-glass-pane unlock-modal" show={show}>
      <Modal.Header>
        <Modal.Title>Session suspended</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleUnlock}>
          <p>Enter your pin</p>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text
              style={FormValidation.validateStyleColor(passcode, isValid)}
              id="inputGroup-sizing-sm"
            >
              <Key />
            </InputGroup.Text>
            <FormControl
              isValid={passcode.length > 3}
              isInvalid={passcode.length > 0 && !isValid}
              required
              size="sm"
              type="password"
              value={passcode}
              placeholder="Access PIN"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Invalid characters.
            </Form.Control.Feedback>
          </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {showAlert && <Alert variant="danger" className="mb-3">Could not unlock your account. Please try again or log out and back in if necessary</Alert>}
        <Button disabled={passcode.length <= 3 || !isValid} onClick={handleUnlock}>
          Unlock
          {loading && <Spinner animation="grow" variant="light" size="sm" />}
        </Button>
      </Modal.Footer>
      <Button onClick={onSignout} variant="secondary" className="py-3 mt-2 sign-out-button">
        Sign Out
      </Button>
    </Modal>
  );
}

export default UnlockModal;
