import DefaultTagCellRenderer from 'components/Table/DefaultTagCellRenderer';
import FetchService from 'services/FetchService';

export default class DatasetsService {
  static getDatasets(clientId) {
    return FetchService.fetchData('GET', `datasets/${clientId}`);
  }

  static getClientCategoryPhrases(clientId) {
    return FetchService.fetchData('GET', `datasets/${clientId}/category-phrases`);
  }

  static doesSystemPhraseContainsSingletons(clientId, phrasePath) {
    return FetchService.fetchData('POST', `datasets/${clientId}/system-phrase-contains-singletons`, {
      phrasePath,
    });
  }

  static async getDatasetTabs(clientId, phraseId, variableId) {
    const tabs = await FetchService.fetchData('POST', 'datasets/tabs', {
      client_id: clientId,
      phrase_id: phraseId,
      variable_id: variableId,
    });

    return tabs.map((t) => {
      if (t.name === 'Data Element') {
        t.questions = t.questions.map((q) => {
          if (q.field === 'display') {
            q.beforeMaskedValueChange = (newState) => {
              const { value } = newState;
              const { selection } = newState;

              return {
                value: value.length > 1 ? `${value})` : '',
                selection,
              };
            };
          }

          return q;
        });
      }

      if (t.name === 'Options') {
        t.questions = t.questions.map((q) => {
          if (q.field === 'options') {
            q.defaultColDef = DatasetsService.getOptionsDefaultColDef();
            q.columnDefs = DatasetsService.getOptionsColumnDefs({});
            q.rowActions = [
              {
                name: (params) => (params.node.data.is_default ? 'Remove Default' : 'Set Default'),
                action: (params) => {
                  if (params.node.data.is_default) {
                    delete params.node.data.is_default;
                  } else {
                    params.api.forEachNode((node) => {
                      delete node.data.is_default;
                    });
                    params.node.data.is_default = true;
                  }
                },
              },
              {
                name: (params) => (params.node.data.status === 'active' ? 'Archive Answer' : 'Unarchive Answer'),
                action: (params) => {
                  params.node.data.status = params.node.data.status === 'archived' ? 'active' : 'archived';
                },
              },
            ];
          }

          return q;
        });
      }

      return t;
    });
  }

  static getCreateDatasetPanel(categoryOptions, groupNameOptions) {
    const questions = [
      {
        label: 'Element Type',
        field: 'element_type',
        type: 'DROPDOWN',
        options: [
          { value: 'free-text', label: 'Free Text' },
          { value: 'structure-list', label: 'Structured List' },
        ],
      },
      {
        label: 'Element Name',
        field: 'element_name',
        type: 'INPUTMASK',
        required: true,
        mask: `${'*'.repeat(20)}`,
        placeholder: 'Value can only have lower case, numbers and or characters _ or - with max length of 20 characters',
        maskChar: '',
        formatChars: {
          '*': '[a-z0-9-_]',
        },
        alwaysShowMask: true,
      },
      {
        label: 'Element Description',
        field: 'element_description',
        type: 'TEXT',
        required: true,
      },
      {
        label: 'Dataset Category',
        field: 'category',
        type: 'MULTISELECT',
        required: true,
        isMulti: false,
        options: categoryOptions,
      },
      {
        label: 'Group Name',
        field: 'group_name',
        type: 'MULTISELECT',
        required: true,
        isMulti: false,
        isCreatable: true,
        placeholder: 'Please select an existing group name, create one or select Auto Create',
        options: [
          { value: 'auto-create', label: '-- Auto Create --' },
          ...groupNameOptions,
        ],
      },
      {
        label: 'Required',
        field: 'required',
        editModeColSize: 6,
        position: 'front',
        description: 'Enabling this would make this field required and will show the asterisk (*)',
        type: 'TOGGLE',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
      {
        label: 'Nullable',
        field: 'nullable',
        description: 'Enabling this would allow users to clear the value once answered',
        editModeColSize: 6,
        position: 'front',
        type: 'TOGGLE',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
      {
        label: 'Value Modifiable',
        field: 'value_modifiable',
        editModeColSize: 6,
        position: 'front',
        description: 'Enabling this would allow the value to be modified',
        type: 'TOGGLE',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
      {
        label: 'Multi-line',
        field: 'multiline',
        editModeColSize: 6,
        position: 'front',
        description: 'Enabling this will allow the user to have a text area to enter long text',
        type: 'TOGGLE',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
        conditions: [
          { field: 'element_type', value: 'free-text' },
        ],
      },
      {
        label: 'Multiple Values',
        field: 'multiple_values',
        editModeColSize: 6,
        position: 'front',
        description: 'Enabling this would allow multiple Datasets to be captured',
        type: 'TOGGLE',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
        conditions: [
          { field: 'element_type', value: 'structure-list' },
        ],
      },
      {
        label: 'Element Value Options',
        field: 'options',
        type: 'EDITABLETABLE',
        addInlineRow: true,
        sizeColumnsToFit: true,
        required: true,
        objectStructure: {
          display: '',
          value: '',
          type: '',
          xor: 'false',
        },
        defaultColDef: this.getOptionsDefaultColDef(),
        columnDefs: this.getOptionsColumnDefs(),
        conditions: [
          { field: 'element_type', value: 'structure-list' },
        ],
      },
    ];

    return {
      title: 'Create Dataset Element',
      key: 'create-dataset-element-panel',
      width: '60%',
      form: {
        id: -1,
        questions,
        submitText: 'Create',
        data: {
          category: categoryOptions?.length === 1 ? categoryOptions[0].value : null,
          element_type: 'free-text',
          required: false,
          nullable: false,
          value_modifiable: false,
          multiline: false,
          multiple_values: false,
          options: [],
        },
      },
    };
  }

  static updateDataset(clientId, id, data) {
    return FetchService.fetchData('PATCH', 'datasets', {
      client_id: clientId,
      id,
      data,
    });
  }

  static getOptionsDefaultColDef() {
    return {
      sortable: false,
      editable: (params) => !params.data.id || ['string', 'number', 'boolean', 'custom', 'encrypted'].includes(params.data.type),
      filter: false,
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      cellStyle: (params) => (params.data?.status === 'archived' ? { color: '#BBB', 'text-decoration': 'line-through' } : {}),
    };
  }

  static getOptionsColumnDefs() {
    return [
      {
        field: 'display',
        rowDrag: true,
        cellRenderer: DefaultTagCellRenderer,
      },
      { field: 'value' },
      {
        field: 'type',
        width: '100',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (() => ({
          values: (() => [
            'string',
            'number',
            'boolean',
            'custom',
            'encrypted',
          ])(),
        }))(),

      },
      {
        field: 'xor',
        width: '75',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['true', 'false'],
          formatValue: (value) => (value.xor ? 'true' : 'false'),
        },
      },
    ];
  }

  static divergeFromShared(clientId, variableId, dataPath) {
    return FetchService.fetchData('POST', 'datasets/diverge', {
      client_id: clientId,
      variable_id: variableId,
      data_path: dataPath,
    });
  }

  static createDatasetElement(clientId, data) {
    return FetchService.fetchData('POST', 'datasets', {
      clientId,
      ...data,
    });
  }
}
