import FetchService from 'services/FetchService';

export default class NotificationsService {
  static getClientNotifications(clientId) {
    return FetchService.fetchData('GET', `notifications?client_id=${clientId}`);
  }

  static getClientNotification(clientId, body) {
    return FetchService.fetchData(
      'POST',
      `notifications?client_id=${clientId}`,
      body,
    );
  }

  static updateClientNotification(clientId, body) {
    return FetchService.fetchData(
      'PATCH',
      `notifications?client_id=${clientId}`,
      body,
    );
  }

  static getUserNotification(clientId, userId, body) {
    return FetchService.fetchData('GET', 'notifications/user/', null, {
      client_id: clientId,
      user_id: userId,
    });
  }

  static async getNotificationsFormQuestions() {
    return FetchService.fetchData('GET', 'notifications/user/form-questions/');
  }
}
