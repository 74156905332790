import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

function UsageFieldList({
  usersData,
  dateData,
  description,
  type,
}) {
  return (
    <div className="usage-field-list">
      <Row>
        <Col xs={8} className="usage-field-list--user">{usersData}</Col>
        <Col xs={4} className="usage-field-list--date">{dateData}</Col>
      </Row>
      { type === 'details' && <small>{description}</small>}

    </div>
  );
}

UsageFieldList.propTypes = {
  usersData: PropTypes.string,
  dateData: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]),
  description: PropTypes.string,
};

UsageFieldList.defaultProps = {
  usersData: '',
  dateData: 0,
  description: '',
};

export default UsageFieldList;
