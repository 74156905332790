import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useParams, useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SearchBar from 'components/Search/SearchBar';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';
import SidePanel from 'components/Table/SidePanel';
import JulotaForm from 'components/Forms/JulotaForm';
import Subheader from 'components/Subheader';
import GenericUtils from 'utils/GenericUtils';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import ComponentsService from '../services/ComponentsService';

export default function Components() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [components, setComponents] = useState('');
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [form, setForm] = useState();
  const [loading, setLoading] = useState(false);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name / Description',
      // eslint-disable-next-line react/no-unstable-nested-components
      cellRenderer: ({ data }) => (
        <DescriptionCellRenderer name={data.name} description={data.description} />
      ),
      sortable: true,
    },
    {
      field: 'component_key',
      headerName: 'Key',
      sortable: true,
    },
    {
      field: 'category',
      sortable: true,
      width: 100,
    },
    {
      field: 'phrase_categories',
      headerName: 'Phrase Categories',
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      width: 100,
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = useCallback(async (params) => {
    const {
      component_key: componentKey,
    } = params.api.getSelectedRows()[0];

    navigate(`/hubs/${clientId}/components/${componentKey}`);
  }, []);

  const createComponent = async () => {
    setForm({
      questions: await ComponentsService.getCreateComponentForm(clientId),
      submitText: 'Create Component',
      data: {
        category: 'Assessment', // Choose the first option in the drop-down by default. currently the DROPDOWN question type doesn't allow empty values, we need to fix that
      },
    });
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      try {
        const { data } = message;
        setLoading(true);
        await ComponentsService.createComponent(clientId, data);
        setFormDirty(false);
        setLoading(false);
        navigate(`/hubs/${clientId}/components/${data.component_key}`);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const closeSidePanel = () => {
    const onConfirm = () => {
      setForm(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const fetchGridData = async () => {
    const res = await ComponentsService.getAll(clientId);

    setComponents(res);
    setGridData(res);
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(components, searchText));
    } else {
      setGridData(components);
    }
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Components</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Components..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="col-md-3 text-align-right">
          <Button
            variant="link"
            onClick={createComponent}
          >
            Create Component
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
          }}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        form && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header title="Create Component" />

            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={form}
                callbackFunction={(message) => handleCallback(message)}
                formDirty={formDirty}
              />
            </SidePanel.Content>

          </SidePanel>
        )
      }
    </div>
  );
}
