import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import debounce from 'lodash.debounce';
import AddressService from 'services/AddressService';
import { Form } from 'react-bootstrap';
import './styles/AddressLookup.scss';

export default function AddressLookup(props) {
  const {
    question,
    value: defaultValue,
    className,
    callbackFunction,
  } = props;
  const [suggestedAddresses, setSuggestedAddresses] = useState();
  const [input, setInput] = useState(defaultValue);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(1);
  const [showList, setShowList] = useState(false);
  const selectedAddressRef = useRef();

  const getAddressesList = (userInput) => {
    AddressService.getAddresses(userInput)
      .then((res) => setSuggestedAddresses(res));
  };

  const selectAddress = (e) => {
    setShowList(false);

    AddressService.getAddressDetail(e.place_id)
      .then((res) => callbackFunction({
        target: {
          name: question.field,
          value: res,
          fieldsToSet: question.fieldsToSet,
        },
      }));

    setSuggestedAddresses(null);
  };

  const debouncedResults = useMemo(() => debounce(getAddressesList, 300));

  const onChange = (e) => {
    setShowList(true);
    setInput(e.target.value);
  };

  const onClick = (e, suggestion) => {
    selectAddress(suggestion);
    setSuggestedAddresses([]);
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveSuggestionIndex((prevState) => (
          prevState + 1 < suggestedAddresses?.length ? prevState + 1 : prevState
        ));
        break;

      case 'ArrowUp':
        e.preventDefault();
        setActiveSuggestionIndex((prevState) => (
          prevState > 0 ? prevState - 1 : prevState
        ));
        break;

      case 'Enter':
        e.preventDefault();
        setInput(selectedAddressRef.current.description);
        selectAddress(selectedAddressRef.current);
        setSuggestedAddresses(null);
        break;

      default:
        break;
    }
  };

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  useEffect(() => {
    if (input === '') {
      AddressService.getAddressDetail('unknown')
        .then((res) => callbackFunction({
          target: {
            name: question.field,
            value: res,
            fieldsToSet: question.fieldsToSet,
          },
        }));
    }

    if (input) {
      debouncedResults(input);
    }
  }, [input]);

  useEffect(() => {
    if (suggestedAddresses) {
      selectedAddressRef.current = suggestedAddresses[activeSuggestionIndex];
    }
  }, [activeSuggestionIndex]);

  return (
    <Form.Group className={className || 'mb-3'}>
      <Form.Label className="label">{question.label}</Form.Label>
      <Form.Control
        type="text"
        onChange={onChange}
        value={input}
        className="autocomplete-component"
        placeholder="Choose your address..."
        onKeyDown={onKeyDown}
      />
      {(suggestedAddresses && showList)
      && (
        <ul
          className="autocomplete-component suggestions"
        >
          {suggestedAddresses.map((suggestion, index) => (
            <li
              className={index === activeSuggestionIndex ? 'suggestions--list-active' : 'suggestions--list'}
              key={suggestion.description}
              onClick={(e) => onClick(e, suggestion)}
              role="presentation"
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </Form.Group>
  );
}
