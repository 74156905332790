import React from 'react';
import { Form } from 'react-bootstrap';

export default function ReadOnly(props) {
  const { question, value } = props;

  return (
    <Form.Group className="readonly">
      <Form.Label className="label">{question.label}</Form.Label>
      <div style={{ marginBottom: 16 }}>
        {
          question.formatter
            ? question.formatter(value)
            : value
        }
      </div>
    </Form.Group>
  );
}
