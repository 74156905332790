/* eslint-disable max-len */
import React,
{
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { selectSessionHasWriteAccess } from 'redux/selectors';
import { useLocation, useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  Key,
  PencilSquare,
  Plus,
  XSquare,
} from 'react-bootstrap-icons';
import {
  Button,
  Tab,
  Tabs,
} from 'react-bootstrap';
import hash from 'object-hash';
import GenericUtils from 'utils/GenericUtils';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import JulotaForm from 'components/Forms/JulotaForm';
import SearchBar from 'components/Search/SearchBar';
import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import UsersService from '../services/UsersService';

export default function Users() {
  const { clientId } = useParams();
  const gridRef = useRef();
  const location = useLocation();

  const [columnDefs] = useState([
    { headerName: 'First Name', field: 'first_name' },
    { headerName: 'Last Name', field: 'last_name' },
    { headerName: 'Username', field: 'username' },
    { headerName: 'Gender', field: 'sex' },
    { headerName: 'Status', field: 'status' },
    { headerName: 'Create Date', field: 'createdt', cellRenderer: (parameters) => GenericUtils.formatDateShort(parameters.value) },
    {
      headerName: 'Last Login',
      field: 'last_login',
      cellRenderer: ({ value }) => (value ? GenericUtils.formatDateShort(value) : ''),
    },
  ]);

  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [create, setCreate] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [formDirty, setFormDirty] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isViewModeTogglable, setIsViewModeTogglable] = useState(true);
  const nonTogglableViewModeTabs = [3, 4, 5, 6];
  const hasWriteAccess = useSelector(selectSessionHasWriteAccess);

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const SubheaderData = [
    `Created on ${GenericUtils.formatDateShort(selectedUser?.createdt)} by ${selectedUser?.createdby}`,
    (selectedUser?.updatedt && selectedUser?.updatedby) && `Update on ${GenericUtils.formatDateShort(selectedUser?.updatedt)} by ${selectedUser?.updatedby}`,
    selectedUser?.last_login && `Last Login: ${GenericUtils.formatDateShort(selectedUser?.last_login)}`,
  ];

  const onGridReady = (pars) => {
    pars.api.sizeColumnsToFit();

    window.onresize = () => {
      pars.api.sizeColumnsToFit();
    };
  };

  const fetchUsers = async () => {
    const res = await UsersService.getUsers(clientId || null);
    setUsers(res);
    setGridData(res);
  };

  const fetchUserDetail = async (id) => {
    setLoading(true);

    const userData = await UsersService.getUserById(id);
    const authData = await UsersService.getUserAuthById(id);
    const teamsData = await UsersService.getUserTeams(id, clientId);
    const orgsData = await UsersService.getUserOrgs(id);
    const hubsData = await UsersService.getUserHubsById(id);
    setSelectedUser(userData);

    setTabs(await UsersService.getTabs(clientId, userData, authData, teamsData, orgsData, hubsData, tabs));

    setLoading(false);
  };

  const onRowClicked = useCallback(() => {
    const onConfirm = () => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      setCreate(false);
      setSelectedRow(selectedRows[0]);
      fetchUserDetail(selectedRows[0].id);
      setFormDirty(false);
      setActiveTab(0);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setSelectedRow(null);
      setTabs(null);
      setCreate(false);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const onCreateClick = async () => {
    setCreate(true);

    setTabs([
      {
        title: 'User Detail',
        form: {
          id: null,
          questions: await UsersService.getUserCreateFormQuestions(),
          data: {
            active: true,
            date_of_birth: '',
            first_name: '',
            last_name: '',
            mfa_email: '',
            mfa_sms: '',
            notification_email: '',
            notification_sms: '',
            notification_strategy: 'default',
            sex: 'Unknown',
            user_id: '',
            username: '',
            send_email: true,
          },
          viewMode: false,
          submitText: 'Create User',
        },
      },
    ]);
    setLoading(false);
    setSelectedRow(null);
  };

  const toggleViewMode = (tabIndex) => {
    const onConfirm = async () => {
      const modifiedTabsState = [...tabs];
      modifiedTabsState[tabIndex].form.viewMode = !modifiedTabsState[tabIndex].form.viewMode;
      setTabs(modifiedTabsState);
      await fetchUserDetail(selectedRow.id);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleSelectTab = (eventKey) => {
    const onConfirm = async () => {
      setActiveTab(parseInt(eventKey, 10));
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleEmailResend = () => {
    const onConfirm = async () => {
      try {
        setLoading(true);
        await UsersService.sendResetPasswordEmail(
          clientId,
          selectedUser.user_id,
          selectedUser.username,
          selectedUser.mfa_email,
        );
        toast.success('Reset password email sent.');
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };

    toast(
      <ConfirmToast
        config={GenericUtils.resetPasswordToastConfig}
        confirm={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const onUserSubmit = async (content) => {
    if (create && !(await UsersService.isUsernameAvailable(content.username))) {
      toast(
        <ConfirmToast
          config={GenericUtils.usernameAlreadyExistsToastConfig}
        />,
        GenericUtils.confirmToastOptions,
      );

      return null;
    }

    const user = await UsersService.createOrUpdateUser(create, {
      user_id: content.user_id,
      client_id: clientId,
      data: create ? content : GenericUtils.getObjectsDifference(selectedUser, content),
    });

    if (create) {
      await fetchUsers(); // Must fetchUsers here in order to be able to select user from table
      setCreate(false);
      gridRef.current.api.forEachNode((node) => {
        if (node.data.id === user.id) {
          node.setSelected(true, true);
          setSelectedRow(node.data);
          setActiveTab(0);
        }
      });
      toast.success('User Created.');
    } else {
      toast.success('User Updated.');
    }

    return user.id;
  };

  const onRolesSubmit = async (content) => {
    await UsersService.updateUserRoles(selectedUser.user_id, content.roles);
    toast.success('User Access Roles Updated.');
  };

  const onAuthSubmit = async (content) => {
    await UsersService.createOrUpdateUser(false, {
      user_id: content.user_id,
      client_id: clientId,
      data: {
        mfa_sms: content.mfa_sms,
        mfa_email: content.mfa_email,
      },
    });

    toast.success('User Authentication Updated.');
  };

  const onTeamsSubmit = async (content) => {
    const oldTeams = await UsersService.getUserTeams(selectedUser.user_id, clientId);
    const newTeams = content.teams;
    const teamsAdded = [];
    const teamsRemoved = [];

    // figure out what teams were added and removed
    newTeams.forEach((newTeam) => {
      const found = oldTeams.find((oldTeam) => oldTeam.id === newTeam.id);
      if (!found) {
        newTeam.user_id = selectedUser.user_id;
        teamsAdded.push(newTeam);
      }
    });

    oldTeams.forEach((oldTeam) => {
      const found = newTeams.find((newTeam) => newTeam.id === oldTeam.id);
      if (!found) {
        oldTeam.user_id = selectedUser.user_id;
        teamsRemoved.push(oldTeam);
      }
    });

    await UsersService.updateUsersTeams(selectedUser.user_id, teamsAdded, teamsRemoved);
    toast.success('User Teams Updated.');
  };

  const onOrganizationsSubmit = async (content) => {
    const oldOrgs = await UsersService.getUserOrgs(selectedUser.user_id);
    const newOrgs = content.organizations;
    const orgsUpdated = [];
    const orgsRemoved = [];

    newOrgs.forEach((newOrg) => {
      const found = oldOrgs.find((oldOrg) => oldOrg.id === newOrg.id);
      if (!found) {
        orgsUpdated.push(newOrg); // Orgs that were added
      }
    });

    oldOrgs.forEach((oldOrg) => {
      const found = newOrgs.find((o) => o.id === oldOrg.id);

      if (!found) {
        orgsRemoved.push(oldOrg.id); // Orgs that were removed
      } else if (JSON.stringify(found) !== JSON.stringify(oldOrg)) {
        orgsUpdated.push(found); // Orgs that were changed
      }
    });

    await UsersService.updateUserOrgs(selectedUser.user_id, orgsUpdated, orgsRemoved);
    toast.success('User Organizations Updated.');
  };

  const onHubsSubmit = async (content) => {
    toast.error('Not Implemented.');
  };

  const onFormSubmit = async (userId, content) => {
    try {
      setLoading(true);

      switch (activeTab) {
        case 0: userId = await onUserSubmit(content); break;
        case 1: await onRolesSubmit(content); break;
        case 2: await onAuthSubmit(content); break;
        case 3: await onTeamsSubmit(content); break;
        case 5: await onOrganizationsSubmit(content); break;
        case 6: await onHubsSubmit(content); break;
        default: break;
      }

      if (isViewModeTogglable) {
        tabs[activeTab].form.viewMode = true;
        setTabs(tabs);
      }

      setFormDirty(false);
      await fetchUsers();
      await fetchUserDetail(userId || selectedUser.user_id);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleCallback = async (message) => {
    if (message.type === 'GENERATE_USERNAME') {
      if (!message.data.first_name || !message.data.last_name) {
        toast.error('Please enter a first and last name to generate a username.');
      } else {
        const firstName = message.data.first_name;
        const lastName = message.data.last_name;
        const username = await UsersService.generateUniqueUsername(firstName, lastName);

        return { username };
      }
    }

    if (message.type === 'ADD_TEAM') {
      const teams = [...message.data.teams];
      const team = message.value;
      teams.push(team);

      return { teams };
    }

    if (message.type === 'REMOVE_TEAM') {
      let onConfirm;
      const team = message.value;

      return toast.promise(
        new Promise((resolve) => { onConfirm = resolve; }),
        {
          pending: {
            render() {
              return (
                <ConfirmToast
                  config={{
                    title: 'Remove from Team',
                    message: `Are you sure you want to remove the user "${selectedUser.username}" from the "${team.name}" team?`,
                    cancelButton: 'Cancel',
                    confirmButton: 'Remove',
                  }}
                  confirm={() => {
                    let teams = [...message.data.teams];
                    teams = teams.filter((t) => t.id !== team.id);

                    onConfirm({ teams });
                  }}
                  cancel={() => {
                    onConfirm(message.data.teams);
                  }}
                />
              );
            },
          },
        },
        { isLoading: false, toastId: message.type },
      );
    }

    if (message.type === 'ADD_ORGANIZATION') {
      const orgs = [...message.data.organizations];
      const newOrg = { ...message.value };

      const found = orgs.find((o) => o.relation === 'primary');
      newOrg.relation = found ? 'secondary' : 'primary';
      orgs.push(newOrg);

      return { organizations: orgs };
    }

    if (message.type === 'REMOVE_ORGANIZATION') {
      let onConfirm;
      const org = message.value;

      return toast.promise(
        new Promise((resolve) => { onConfirm = resolve; }),
        {
          pending: {
            render() {
              return (
                <ConfirmToast
                  config={UsersService.removeUserOrganizationToastConfig(selectedUser.username, org.name)}
                  confirm={() => {
                    let orgs = [...message.data.organizations];
                    orgs = orgs.filter((o) => o.id !== org.id);

                    onConfirm({ organizations: orgs });
                  }}
                  cancel={() => {
                    onConfirm(message.data.organizations);
                  }}
                />
              );
            },
          },
        },
        { isLoading: false, toastId: message.type },
      );
    }

    if (message.type === 'MARK_PRIMARY_ORGANIZATION') {
      const orgs = [...message.data.organizations];
      const newPrimaryOrg = { ...message.value };

      orgs.map((org) => {
        if (org.relation === 'primary') { org.relation = 'secondary'; }
        if (org.id === newPrimaryOrg.id) { org.relation = 'primary'; }

        return org;
      });

      return { organizations: orgs };
    }

    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
      if (create && message.name === 'first_name') {
        return { nickname: message.value };
      }
    } else if (message.type === 'FORM_SUBMITTED') {
      const { id, data } = message;
      onFormSubmit(id, data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (nonTogglableViewModeTabs.includes(activeTab)) {
      setIsViewModeTogglable(false);
    } else {
      setIsViewModeTogglable(true);
    }
  }, [activeTab]);

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(users, search));
    } else {
      setGridData(users);
    }
  }, [search]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Users</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6 search-bar-users">
          <SearchBar
            placeholder="Search Users..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {location.pathname.includes('hubs')
          && (
            <div className="col-md-3 text-align-right">
              <Button variant="link" size="sm" onClick={onCreateClick}>
                <Plus />
                <span>Add User</span>
              </Button>
            </div>
          )}
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="single"
          suppressContextMenu="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
          animateRows
          suppressScrollOnNewData="true"
        />
      </div>

      {
        ((selectedRow && tabs) || create)
        && (
          <SidePanel
            close={closeSidePanel}
            loading={loading}
            confirmClosePrompt={formDirty}
            style={{ width: '60%', minWidth: '700px' }}
          >
            <SidePanel.Header
              title={create ? 'Add User' : `${selectedUser?.first_name} ${selectedUser?.last_name}`}
              style={{ borderBottom: 0 }}
            >
              {!create && <Subheader description={SubheaderData} />}
            </SidePanel.Header>

            <SidePanel.Content>
              <div className="tabs-container">
                <Tabs
                  onSelect={handleSelectTab}
                  activeKey={activeTab}
                  defaultActiveKey={0}
                  mountOnEnter
                  unmountOnExit
                >
                  {tabs?.map((tab, index) => (
                    <Tab
                      eventKey={index}
                      title={tab.title}
                      key={hash(tab)}
                    >
                      <div>
                        {!create && hasWriteAccess && (
                          <div className="float-right">
                            {index === 0 && (
                              <Button disabled={!tab.form?.viewMode} variant="link" size="sm" onClick={() => handleEmailResend()}>
                                <Key />
                                <span>Reset Password</span>
                              </Button>
                            )}
                            {isViewModeTogglable && (
                              <Button variant="link" size="sm" onClick={() => toggleViewMode(index)}>
                                {tab.form?.viewMode ? <PencilSquare /> : <XSquare /> }
                                <span>{tab.form?.viewMode ? 'Edit' : 'Cancel'}</span>
                              </Button>
                            )}
                          </div>
                        )}

                        <JulotaForm
                          inputData={tab.form}
                          callbackFunction={(message) => handleCallback(message)}
                          viewMode={tab.form?.viewMode}
                          formDirty={formDirty}
                        />

                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
