import FetchService from 'services/FetchService';

export default class MedicalInsurersService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `medical-insurers?client_id=${clientId}`);
  }

  static async getMedicalInsurer(clientId, id) {
    return FetchService.fetchData('GET', `medical-insurers/${id}?client_id=${clientId}`);
  }

  static upsertMedicalInsurer(clientId, id, payload) {
    if (id) {
      return FetchService.fetchData('PUT', `medical-insurers/${id}?client_id=${clientId}`, payload);
    }

    return FetchService.fetchData('POST', `medical-insurers?client_id=${clientId}`, payload);
  }

  static async getQuestions() {
    return [
      {
        label: 'Status',
        field: 'status',
        type: 'DROPDOWN',
        required: true,
        options: [
          { label: 'Active', value: 'Active' },
          { label: 'Inactive', value: 'Inactive' },
        ],
      },
      {
        label: 'Name',
        field: 'name',
        type: 'TEXT',
        required: true,
      },
    ];
  }
}
