import React from 'react';
import { Form } from 'react-bootstrap';
import './styles/RangeInput.scss';

export default function RangeSlider(props) {
  const {
    question,
    value,
    callbackFunction,
  } = props;

  const [minValue, setMinValue] = React.useState(value ? value.min : question.min);
  const [maxValue, setMaxValue] = React.useState(value ? value.max : question.max);

  React.useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - question.step);
    if (!value) setMinValue(newMinVal);
    callbackFunction({
      target: {
        name: question.field,
        value: { min: newMinVal, max: maxValue },
      },
    });
  };

  const handleMaxChange = (e) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + question.step);
    if (!value) setMaxValue(newMaxVal);
    callbackFunction({
      target: {
        name: question.field,
        value: { min: minValue, max: newMaxVal },
      },
    });
  };

  const minPos = ((minValue - question.min) / (question.max - question.min)) * 100;
  const maxPos = ((maxValue - question.min) / (question.max - question.min)) * 100;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <div>
        <small>{question.description}</small>
      </div>
      <div className="wrapper">
        <div className="input-wrapper">
          <input
            className="input"
            type="range"
            value={minValue}
            min={question.min}
            max={question.max}
            step={question.step}
            onChange={handleMinChange}
          />
          <input
            className="input"
            type="range"
            value={maxValue}
            min={question.min}
            max={question.max}
            step={question.step}
            onChange={handleMaxChange}
          />
        </div>

        <div className="control-wrapper">
          <div className="number" style={{ left: `${minPos}%` }}>
            {minValue}
          </div>
          <div className="control" style={{ left: `${minPos}%` }} />
          <div className="rail">
            <div
              className="inner-rail"
              style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
            />
          </div>
          <div className="number" style={{ left: `${maxPos}%` }}>
            {maxValue}
          </div>
          <div className="control" style={{ left: `${maxPos}%` }} />
        </div>
      </div>
    </Form.Group>
  );
}
