/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';
import DatasetsService from './DatasetsService';

export default class ComponentsService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `components/all?client_id=${clientId}`);
  }

  static getComponent(key, clientId, versionNumber) {
    const url = versionNumber
      ? `components/${key}?client_id=${clientId}&version_number=${versionNumber}`
      : `components/${key}?client_id=${clientId}`;

    return FetchService.fetchData('GET', url);
  }

  static async getVersionList(key, clientId) {
    const versionList = await FetchService.fetchData('GET', `components/${key}/versions?client_id=${clientId}`);

    return versionList.map((v) => (
      {
        label: `${v.name} (${v.status})`,
        value: v.version_number,
      }
    ));
  }

  static async getClientCategories(clientId) {
    const categories = await FetchService.fetchData('GET', `clients/${clientId}/data-elements/categories`);

    return categories.map((c) => (
      {
        label: c.category,
        value: c.category,
      }
    ));
  }

  static async getEnumValues(enumName) {
    const componentCategories = await FetchService.fetchData('GET', `lookup/enum/${enumName}`);

    return componentCategories.map((c) => ({ label: c.label, value: c.label }));
  }

  static async getCreateComponentForm(clientId) {
    const clientCategories = await this.getClientCategories(clientId);
    const componentCategories = await this.getEnumValues('app_layouts.component_category');

    return [
      {
        label: 'Category',
        field: 'category',
        type: 'DROPDOWN',
        required: true,
        options: componentCategories,
      },
      {
        label: 'Phrase Categories',
        field: 'phraseCategories',
        type: 'MULTISELECT',
        required: true,
        options: clientCategories,
      },
      {
        label: 'Component Key',
        field: 'component_key',
        type: 'INPUTMASK',
        required: true,
        mask: `${'*'.repeat(100)}`,
        placeholder: 'Value can only have lower case, numbers and or characters _ or -',
        maskChar: '',
        formatChars: {
          '*': '[a-z0-9-_]',
        },
        alwaysShowMask: true,
      },
      {
        label: 'Name',
        field: 'name',
        type: 'TEXT',
        required: true,
      },
      {
        label: 'Description',
        field: 'description',
        type: 'TEXTAREA',
        required: true,
      },
      {
        label: 'Title',
        field: 'title',
        type: 'TEXT',
        required: true,
      },
    ];
  }

  static async getComponentForm(clientId, componentKey) {
    const facts = await this.getComponentFacts(clientId, componentKey, null);
    const clientCategories = await this.getClientCategories(clientId);

    return [
      {
        label: 'Category',
        field: 'category',
        type: 'TEXT',
        required: true,
        disabled: true, // Once a component is created we cannot allow to change category without checking first if that component is being used in layout where it shouldn't be used
      },
      {
        label: 'Phrase Categories',
        field: 'phraseCategories',
        type: 'MULTISELECT',
        disabled: true,
        options: clientCategories,
      },
      {
        label: 'Component Key',
        field: 'componentKey',
        type: 'TEXT',
        required: true,
        disabled: true,
      },
      {
        label: 'Name',
        field: 'name',
        type: 'TEXT',
        required: true,
      },
      {
        label: 'Description',
        field: 'description',
        type: 'TEXTAREA',
        required: true,
      },
      {
        label: 'Title',
        field: 'title',
        type: 'TEXT',
        required: true,
      },
      {
        label: 'Display Rules',
        description: 'Add custom logic to configure when component is displayed',
        field: 'rules',
        type: 'RULES',
        options: facts,
      },
    ];
  }

  static createComponent(clientId, data) {
    return FetchService.fetchData('POST', 'components', {
      clientId,
      ...data,
    });
  }

  static async getComponentRowForm(clientId, componentKey, item, elementsFilter) {
    const phrasePath = item ? item.elements[0].data_path.split('.')[0] : null;
    const dataPath = item && item.elements.length === 1 ? item.elements[0].data_path : null;

    const facts = await this.getComponentFacts(clientId, componentKey, dataPath);
    const dataElements = facts.find((i) => i.type === 'data-element').facts.map((d) => d.element);

    let filteredDataElements = dataElements;
    if (phrasePath) {
      filteredDataElements = filteredDataElements.filter((e) => e.includes(phrasePath));
    }

    if (elementsFilter.length > 0) {
      filteredDataElements = filteredDataElements.filter((e) => !elementsFilter.includes(e));
    }

    const dataElementRulesScope = {
      label: 'Data Elements',
      field: 'data_paths',
      options: item ? item.elements.map((i) => ({ value: i.data_path, label: i.data_path })) : [],
    };

    const valueOptions = [];
    item?.elements.forEach((e) => {
      e.options?.forEach((o) => {
        if (o.label !== 'custom' && o.label !== null) {
          valueOptions.push({ value: o.value, label: o.value });
        }
      });
    });

    const phraseContainsSingletons = await DatasetsService.doesSystemPhraseContainsSingletons(clientId, phrasePath);
    const displayAllowMultipleVariables = !phraseContainsSingletons;

    return [
      {
        label: 'Type',
        field: 'container_type',
        type: 'DROPDOWN',
        required: true,
        options: [
          { label: 'Data Elements', value: 'data-elements' },
          { label: 'Markdown', value: 'markdown' },
        ],
      },
      {
        label: 'Row Layout',
        field: 'row_layout',
        type: 'DROPDOWN',
        required: true,
        options: [
          { label: 'Full', value: 'full' },
          { label: 'Half Left', value: 'half-left' },
          { label: 'Half Right', value: 'half-right' },
          { label: 'Half Floated', value: 'half-floated' },
        ],
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      {
        label: 'Container Layout',
        field: 'layout',
        type: 'DROPDOWN',
        required: true,
        options: [
          { label: '25/75', value: '25-75' },
          { label: '50/50', value: '50-50' },
          { label: 'Above Data Element', value: 'above' },
        ],
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      {
        label: 'Default Label',
        field: 'label',
        type: 'TEXT',
        disabled: true,
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      {
        label: 'Label Overwrite',
        field: 'label_overwrite',
        type: 'TEXT',
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      ...displayAllowMultipleVariables ? [{
        label: 'Allow Multiple Values',
        field: 'allow_many',
        description: 'Enabling this will allow the collection of multiple set of values for all elements in container',
        type: 'TOGGLE',
        options: [
          { label: 'Yes', value: true },
          { label: 'no', value: false },
        ],
      }] : [],
      {
        label: 'Min and Max Cardinality',
        description: 'When multiple values are allow for the set you can choose what are the minimun and maximum number to be collected',
        field: 'min_max_cardinality',
        type: 'RANGESLIDER',
        min: 0,
        max: 20,
        step: 1,
        conditions: [
          { field: 'container_type', value: 'data-elements' },
          { field: 'allow_many', value: true },
        ],
      },
      {
        label: 'Data Elements',
        field: 'elements',
        autoSizeAllColumns: true,
        addInlineRow: true,
        type: 'EDITABLETABLE',
        columnDefs: [
          {
            headerName: 'Data Path',
            field: 'data_path',
            width: 400,
            cellEditorSelector: (params) => ({
              component: 'agSelectCellEditor',
              params: {
                values: filteredDataElements,
              },
            }),
            editable: (params) => params.data.data_path === undefined,
            rowDrag: true,
            sortable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
            pinned: 'left',
          },
          { headerName: 'Placeholder', field: 'placeholder' },
          {
            headerName: 'Display As',
            field: 'display_as',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (() => ({
              values: (() => [
                'text',
                'multiline-text',
                'dropdown',
                'button-group',
                'radio-group',
                'check-group',
                'date',
                'time',
                'elapsed-time',
                'file-upload',
              ])(),
            }))(),
          },
          {
            headerName: 'Max Columns',
            field: 'display_max_columns',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (() => ({
              values: (() => [
                1,
                2,
                3,
                4,
              ])(),
            }))(),
          },
          {
            headerName: 'Default Value Visibility',
            field: 'default_value_visibility',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (() => ({
              values: (() => [
                'true',
                'false',
              ])(),
            }))(),
          },
          {
            headerName: 'Readonly',
            field: 'readonly',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: (() => ({
              values: (() => [
                'true',
                'false',
              ])(),
            }))(),
          },
          { headerName: 'Read Only Empty Text', field: 'readonly_empty_text' },
          { headerName: 'Before Text', field: 'before_text' },
          { headerName: 'After Text', field: 'after_text' },
          { headerName: 'Informative Text', field: 'informative_text' },
          { headerName: 'Instructional Text', field: 'instructional_text' },
        ],
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      {
        label: 'Markdown Text',
        field: 'markdown_text',
        type: 'TEXT',
        conditions: [
          { field: 'container_type', value: 'markdown' },
        ],
      },
      {
        label: 'Element Visible Rules',
        description: 'This rules would apply to all the data elements in this container',
        field: 'visible_rules',
        type: 'RULES',
        scope: dataElementRulesScope,
        options: facts,
      },
      {
        label: 'Element Require Rules',
        description: 'This rules would apply to all the data elements in this container',
        field: 'require_rules',
        type: 'RULES',
        scope: dataElementRulesScope,
        options: facts,
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      },
      ...valueOptions.length > 0 ? [{
        label: 'Value Display Rules',
        field: 'value_display_rules',
        type: 'RULES',
        scope: {
          label: 'Values',
          field: 'value_paths',
          options: valueOptions,
        },
        options: facts,
        conditions: [
          { field: 'container_type', value: 'data-elements' },
        ],
      }] : [],
    ];
  }

  static getComponentFacts(clientId, componentKey, dataPath) {
    return FetchService.fetchData('GET', `components/${componentKey}/facts?client_id=${clientId}&data_path=${dataPath}`);
  }

  static saveComponentDraft(componentKey, clientId, component) {
    return FetchService.fetchData('POST', `components/${componentKey}/draft`, {
      clientId,
      layout: component.layout,
    });
  }

  static deleteComponentDraft(versionId) {
    return FetchService.fetchData('DELETE', `components/draft/${versionId}`);
  }
}
