/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';
import hash from 'object-hash';
import TeamService from 'modules/clients/services/TeamService';
import NotificationsService from 'modules/clients/services/NotificationsService';

export default class UsersService {
  static getUsers(clientId) {
    if (clientId) {
      return FetchService.fetchData('GET', `clients/${clientId}/users`);
    }

    return FetchService.fetchData('GET', 'users');
  }

  static generateUniqueUsername(firstName, lastName) {
    return FetchService.fetchData('POST', 'users/generateUsername/', null, {
      first_name: firstName,
      last_name: lastName,
    });
  }

  static isUsernameAvailable(username) {
    if (!username) { return false; }

    return FetchService.fetchData('GET', 'users/isUsernameAvailable/', null, {
      username,
    });
  }

  static getUserById(id) {
    return FetchService.fetchData('GET', `users/${id}`);
  }

  static getDetailsFormQuestions() {
    return FetchService.fetchData('GET', 'users/details-form-questions');
  }

  static getUserHubsById(userId) {
    return FetchService.fetchData('GET', 'users/hubs', null, { user_id: userId });
  }

  static getHubsFormQuestions() {
    return FetchService.fetchData('GET', 'users/hubs/form-questions');
  }

  static getUserAuthById(userId) {
    return FetchService.fetchData('GET', 'users/auth', null, { user_id: userId });
  }

  static getAuthFormQuestions(type) {
    return FetchService.fetchData('GET', 'users/auth/form-questions', null, { type });
  }

  static async getAllUserRoles(userId) {
    const clientRoles = await FetchService.fetchData('GET', `users/${userId}/roles/all`);

    return clientRoles.map((r) => ({
      label: `${r.description} (${r.name})`,
      value: r.name,
    }));
  }

  static async getRolesByUserId(userId) {
    const data = await FetchService.fetchData('GET', `users/${userId}/roles`);

    return data.map((r) => r.name);
  }

  static async updateUserRoles(userId, roles) {
    await FetchService.fetchData('PATCH', `users/${userId}/roles`, { roles });
  }

  static getUserTeams(userId, clientId) {
    return FetchService.fetchData('GET', `users/${userId}/teams`, null, {
      client_id: clientId,
    });
  }

  static updateUsersTeams(userId, add, remove) {
    return FetchService.fetchData('PUT', `users/${userId}/teams`, { add, remove });
  }

  static sendResetPasswordEmail(clientId, userId, username, email) {
    return FetchService.fetchData('POST', 'users/reset-password', {
      client_id: clientId,
      user_id: userId,
      username,
      email,
    });
  }

  static updateUser(data) {
    return FetchService.fetchData('POST', 'users', data);
  }

  static createUser(data) {
    const user = FetchService.fetchData(
      'POST',
      `clients/${data.client_id}/users`,
      {
        username: data.data.username,
        tempPassword: null,
        first_name: data.data.first_name,
        last_name: data.data.last_name,
        date_of_birth: data.data.date_of_birth,
        sex: data.data.sex || null,
        avatar:
          data.data.sex === 'Male'
            ? 'male-white-light-15'
            : 'female-white-light-12',
        email_address: data.data.mfa_email,
        sms: data.data.mfa_sms || null,
        send_email:
          typeof data.data.send_email === 'boolean'
            ? data.data.send_email
            : true,
      },
    );

    return user;
  }

  static getUserCreateFormQuestions() {
    return FetchService.fetchData('GET', 'users/create-form-questions');
  }

  static createOrUpdateUser(create, data) {
    if (create) {
      return this.createUser(data);
    }

    return this.updateUser(data);
  }

  static deleteUserById(id) {
    FetchService.fetchData('DELETE', `users/${id}`);
  }

  static getUserOrgs(userId) {
    return FetchService.fetchData('GET', 'users/organizations', null, {
      user_id: userId,
    });
  }

  static async getUserOrgsFormQuestions(clientId) {
    return FetchService.fetchData('GET', 'users/organizations/form-questions', null, {
      client_id: clientId,
    });
  }

  static removeUserOrganizationToastConfig = (username, orgName) => ({
    title: 'Remove from Organization',
    message: `Are you sure you want to remove the user "${username}" from the "${orgName}" Organization?`,
    cancelButton: 'Cancel',
    confirmButton: 'Remove',
  });

  static updateUserOrgs(userId, update, remove) {
    return FetchService.fetchData('PUT', 'users/organizations', { userId, update, remove });
  }

  static async getTabs(clientId, userData, authData, teamsData, orgsData, hubsData, currentTabs) {
    const tabs = [
      {
        title: 'User Detail',
        form: {
          id: userData.user_id,
          questions: await UsersService.getDetailsFormQuestions(),
          data: UsersService.getDetailsFormData(userData),
          viewMode:
            currentTabs && typeof currentTabs[0]?.form.viewMode === 'boolean'
              ? currentTabs[0].form.viewMode
              : true,
        },
      },
      {
        title: 'Access Roles',
        form: {
          id: userData.user_id,
          questions: [
            {
              label: 'Roles',
              field: 'roles',
              type: 'MULTISELECT',
              options: await UsersService.getAllUserRoles(userData.user_id),
              formatter: (value) => (
                <ul>
                  {(typeof value !== 'string') && value.map((item) => (
                    <li key={hash(item)}>{item}</li>
                  ))}
                </ul>
              ),
            },
          ],
          data: {
            roles: await UsersService.getRolesByUserId(userData.user_id),
          },
          viewMode:
            currentTabs && typeof currentTabs[1]?.form.viewMode === 'boolean'
              ? currentTabs[1].form.viewMode
              : true,
        },
      },
      {
        title: 'Authentication',
        form: {
          id: userData.user_id,
          questions: await UsersService.getAuthFormQuestions(authData.type),
          data: UsersService.getAuthFormData(authData, userData),
          viewMode:
            currentTabs && typeof currentTabs[2]?.form.viewMode === 'boolean'
              ? currentTabs[2].form.viewMode
              : true,
        },
      },
      {
        title: 'Teams',
        form: {
          id: userData.user_id,
          questions: await TeamService.getTeamsFormQuestions(
            authData.client_id,
          ),
          data: { teams: teamsData },
          viewMode: false,
        },
      },
      {
        title: 'Notifications',
        form: {
          id: userData.user_id,
          questions: await NotificationsService.getNotificationsFormQuestions(),
          // eslint-disable-next-line max-len
          data: { notifications: await NotificationsService.getUserNotification(authData.client_id, userData.user_id) },
          viewMode: false,
        },
      },
      {
        title: 'Organizations',
        form: {
          id: userData.user_id,
          questions: await UsersService.getUserOrgsFormQuestions(authData.client_id),
          data: { organizations: orgsData },
          viewMode: false,
        },
      },
      {
        title: 'Hubs',
        form: {
          id: userData.user_id,
          questions: await UsersService.getHubsFormQuestions(),
          data: { hubs: hubsData },
          viewMode: false,
        },
      },
    ];

    return tabs;
  }

  static getDetailsFormData(user) {
    return {
      id: user?.user_id ?? '',
      ...user,
    };
  }

  static getAuthFormData(auth, user) {
    return {
      ...auth,
      mfa_email: user.mfa_email,
      mfa_sms: user.mfa_sms,
    };
  }
}
