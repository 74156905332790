import FetchService from './FetchService';

export default class AddressService {
  static async getAddresses(query) {
    const response = await FetchService.fetchData('GET', `lookup/address?query=${query}`);

    return [{ place_id: 'unknown', description: 'Unknown' }, ...response];
  }

  static async getAddressDetail(placeid) {
    const addressDetail = placeid === 'unknown' ? null : await FetchService.fetchData('GET', `lookup/address/detail/${placeid}`);

    const roundToSix = (num) => parseFloat(Number(num).toFixed(6));
    const lat = addressDetail?.gps.lat ? roundToSix(addressDetail.gps.lat) : '0.00';
    const lng = addressDetail?.gps.lng ? roundToSix(addressDetail.gps.lng) : '0.00';

    return [
      {
        name: 'primary_address',
        value: addressDetail?.formatted_address ?? 'Unknown',
      },
      {
        name: 'postal',
        value: addressDetail?.postal_code ?? 11111,
      },
      {
        name: 'lat_lng',
        value: `${lat},${lng}`,
      },
    ];
  }
}
