import moment from 'moment-timezone';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import UsageFieldList from './UsageFieldList';

function UsageGroupByDate({ data }) {
  const years = Object.keys(data);
  years.reverse();
  const sortData = (info) => {
    const resort = Object.keys(info);
    resort.reverse();
    return resort;
  };
  return (
    <div>
      {years.map((item) => {
        const resort = sortData(data[item]);
        return (
          <div key={item}>
            {resort.map((m) => (
              <div key={m}>
                <div className="usage-list-year">
                  <p className="usage-list-year--item">{`${moment.months(m - 1)}, ${item}`}</p>
                  <p className="usage-list-year--line" />
                </div>
                <ListGroup variant="flush">
                  {data[item][m].map((list) => (
                    <ListGroup.Item className="usage-listgroup" key={list.did}>
                      <UsageFieldList
                        usersData={list.event}
                        dateData={`${list.month}, ${item}`}
                        description={list.description}
                        type="details"
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default UsageGroupByDate;
