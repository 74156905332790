import {
  React,
  useEffect,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import hash from 'object-hash';
import JulotaForm from 'components/Forms/JulotaForm';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import PlaceholderSkeleton from 'components/Skeleton/PlaceholderSkeleton';
import SidePanel from 'components/Table/SidePanel';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import GenericUtils from 'utils/GenericUtils';
import EntityService from '../services/EntityService';

export default function EntityList(props) {
  const { clientId } = useParams();
  const { entityType } = props;

  // States
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [sidePanel, setSidePanel] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [columnDefs, setColumnDefs] = useState([]);

  let gridApi = null;

  const navigate = useNavigate();

  const renderComponent = async () => {
    setColumnDefs(EntityService.getColumnDefs(entityType));
    const pData = await EntityService.getPageData(clientId, entityType);
    setPageData(pData);
    setGridData(pData.data);
    // gridApi.sizeColumnsToFit();
  };

  const onGridReady = (params) => {
    gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = async (params) => {
    const { id, key } = params.api.getSelectedRows()[0];
    navigate(`${key}/${id}`);
  };

  const closeSidePanel = () => {
    const onConfirm = () => {
      setSidePanel(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { id, data } = message;

      setLoading(true);

      try {
        const response = await EntityService.createOrUpdate(clientId, entityType, id, {
          clientId,
          key: sidePanel.key,
          ...data,
        });

        toast.success(response);
        setFormDirty(false);
        setSidePanel(null);
        renderComponent();
      } catch (error) {
        toast.error(error);
      }

      setLoading(false);
    }
  };

  const onSelectedTemplate = async (item) => {
    setLoading(true);
    const template = await EntityService.getTemplate(entityType, item.value);
    setSidePanel({
      title: item.label,
      key: item.value,
      form: template,
    });
    setLoading(false);
    setFormDirty(true);
  };

  useEffect(() => {
    renderComponent();
  }, [entityType]);

  useEffect(() => {
    const searchedData = searchText.length > 0
      ? GenericUtils.search(pageData.data, searchText)
      : pageData.data;
    setGridData(searchedData);
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2 className="capitalize">{`${entityType}s`}</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder={`Search ${entityType}s...`}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col-md-3 text-align-right">
          {
            pageData.templates ? (
              <DropdownButton title={`Add ${entityType}s`} variant="link" disabled={pageData.templates.length < 1}>
                { pageData.templates.map((item) => (
                  <Dropdown.Item
                    onClick={() => onSelectedTemplate(item)}
                    key={hash(item)}
                  >
                    {`${item.label} (${item.value})`}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            ) : (
              <PlaceholderSkeleton rows={1} />
            )
          }
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
          }}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
          onDataRendered={onGridReady}
        />
      </div>
      {
        sidePanel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header title={sidePanel.title} subtitle={sidePanel.key} />
            <SidePanel.Content className="no-tabs">
              {
                sidePanel.form && (
                  <JulotaForm
                    inputData={sidePanel.form}
                    callbackFunction={(message) => handleCallback(message)}
                    formDirty={formDirty}
                  />
                )
              }
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
