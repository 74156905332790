import FetchService from 'services/FetchService';

export default class ModulesService {
  static getClientModules(clientId) {
    return FetchService.fetchData('POST', 'modules', {
      client_id: clientId,
    });
  }

  static getModuleConfig(clientId, moduleKey) {
    return FetchService.fetchData('POST', `modules/${moduleKey}`, {
      client_id: clientId,
    });
  }

  static updateModuleConfig(moduleKey, body) {
    return FetchService.fetchData('PUT', `modules/${moduleKey}`, body);
  }

  static validateJSON(questionField, body) {
    return FetchService.fetchData('POST', `modules/${questionField}/validate`, body);
  }
}
