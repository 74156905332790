/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import * as AgGrid from 'ag-grid-enterprise';
import './index.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

AgGrid.LicenseManager.setLicenseKey('CompanyName=TouchPhrase Development, LLC,LicensedApplication=Julota,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=20,LicensedProductionInstancesCount=10,AssetReference=AG-026317,ExpiryDate=14_March_2033_[v2]_MTY3ODc1MjAwMDAwMA==0d1356926adac611cb4c0e79024c77c0');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
