import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';

export default class DatasetsHelper {
  constructor() {
    this.statusStyle = (params) => {
      switch (params.value) {
        case 'active': return { color: 'var(--color-active)' };
        case 'inactive': return { color: 'var(--color-inactive)' };
        case 'archived': return { color: 'var(--color-archived)' };
        case 'deleted': return { color: 'var(--color-deleted)' };
        default: return { color: 'var(--color-black)' };
      }
    };
    this.DatasetsDef = {
      columnDefs: [
        {
          headerName: 'Data Element',
          field: 'variable_header',
          cellRenderer: ({ data }) => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <DescriptionCellRenderer name={data.variable_header} description={data.data_path} />
          ),
          width: '600',
        },
        { headerName: 'Scope', field: 'scope', width: '150' },
        { headerName: 'Type', field: 'type' },
        {
          headerName: 'Status',
          field: 'variable_status',
          width: '100',
          cellStyle: this.statusStyle,
          cellClass: 'capitalize',
        },
        { headerName: 'Created Date', field: 'createdt', cellRenderer: this.formatDateShort },
      ],
    };

    this.results = {};
    this.setInitialStateDatasets = this.setInitialStateDatasets.bind(this);
  }

  setInitialStateDatasets(Element) {
    this.results = this.DatasetsDef;
    const targetResult = this.results.columnDefs;
    let target = {};
    let index = 0;
    this.results.columnDefs.forEach((item, j) => {
      if (item.field === 'createdt') {
        target = item;
        index = j;
      }
    });
    target.cellRenderer = Element;
    targetResult[index] = target;
    this.results.columnDefs = targetResult;

    return this.results;
  }
}
