import FetchService from 'services/FetchService';

export default class GroupService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `groups?client_id=${clientId}`);
  }

  static async getGroup(clientId, id) {
    return FetchService.fetchData('GET', `groups/${id}?client_id=${clientId}`);
  }

  static upsertGroup(clientId, id, payload) {
    if (id) {
      return FetchService.fetchData('PUT', `groups/${id}?client_id=${clientId}`, payload);
    }

    return FetchService.fetchData('POST', `groups?client_id=${clientId}`, payload);
  }

  static async getQuestions(clientId) {
    return FetchService.fetchData('GET', `groups/questions?client_id=${clientId}`);
  }
}
