import FetchService from 'services/FetchService';

export default class AuthService {
  static login = (data) => FetchService.fetchData('POST', 'auth/session', data);

  static loginSSO = (data) => FetchService.fetchData('POST', 'auth/session-sso', data);

  static requestToken = (data) => FetchService.fetchData('POST', 'auth/token', data);

  static activateSession = (data) => FetchService.fetchData('POST', 'auth/session/activate', data);

  static getSession = () => FetchService.fetchData('GET', 'auth/session');

  static lockSession = () => FetchService.fetchData('POST', 'auth/session/lock');

  static unlockSession = (data) => FetchService.fetchData('POST', 'auth/session/unlock', data);

  static deleteSession = () => FetchService.fetchData('DELETE', 'auth/session');
}
