import moment from 'moment-timezone';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import UsageFieldList from './UsageFieldList';
import UsageLoading from './UsageLoading';

function UsageActives({ data, loading }) {
  const resort = Object.keys(data);
  resort.reverse();
  if (loading) {
    return <UsageLoading />;
  }
  return (
    <div>
      {resort.map((item) => (
        <div key={item}>
          <div className="usage-list-year">
            <p className="usage-list-year--item">
              {item}
            </p>
            <p className="usage-list-year--line" />
          </div>
          <ListGroup variant="flush">
            {data[item].map((list) => (
              <ListGroup.Item className="usage-listgroup" key={list.id}>
                <UsageFieldList
                  usersData={moment.months(list.month - 1)}
                  dateData={list.active}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ))}
    </div>
  );
}

export default UsageActives;
