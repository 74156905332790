import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import TimePicker from 'react-time-picker';

export default function DatePicker(props) {
  const [value, onChange] = useState(null);

  return (
    <TimePicker value={value} onChange={onChange} />
  );
}
