import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import hash from 'object-hash';
import { Tab, Tabs } from 'react-bootstrap';
import CustomLink from 'components/CustomLink';
import JulotaForm from 'components/Forms/JulotaForm';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import GenericUtils from 'utils/GenericUtils';
import EntityService from '../services/EntityService';

import '../styles/Profile.scss';
import Layout from './Layout';

export default function Entity(props) {
  const { clientId, entityKey, id } = useParams();
  const { entityType } = props;
  const layoutKey = `${entityKey}-layout`; // TODO: Fix this hardcode

  // States
  const [tabs, setTabs] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [formDirty, setFormDirty] = useState(false);

  const fetchData = async () => {
    setTabs(await EntityService.getTabs(clientId, entityType, entityKey, id));
  };

  const saveConfiguration = async (data) => {
    try {
      const response = await EntityService.createOrUpdate(clientId, entityType, id, {
        clientId,
        entityKey,
        ...data,
      });

      toast.success(response);
      setFormDirty(false);
      fetchData();
    } catch (error) {
      toast.error(error);
    }
  };

  const callbackFunction = async (message) => {
    if (message.type === 'FORM_SUBMITTED') {
      const selectedTab = tabs[activeTab];

      if (selectedTab.type === 'JULOTA_FORM') {
        saveConfiguration(message.data);
      }

      setFormDirty(false);
    } else if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'ADD_TASK') {
      if (message.value) {
        const service = { ...message.data };
        const task = {
          type: message.value.type,
          key: message.value.key,
          active: message.value.active,
          mandatory_status: message.value.mandatory_status,
          pending_display: message.value.pending_display,
          completed_display: message.value.completed_display,
        };

        service.configuration.components
          .find(({ type }) => type === 'tasks')?.settings.tasks
          .unshift(task);

        toast.success(`${message.value.label} ${message.value.type} Successfully Added.`);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectTab = (eventKey) => {
    const onConfirm = async () => {
      setActiveTab(parseInt(eventKey, 10));
      setFormDirty(false);
      fetchData();
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const renderTab = (tab) => {
    switch (tab.type) {
      case 'LAYOUT_CONFIGURATION': return <Layout layoutKey={layoutKey} />;
      case 'JULOTA_FORM': return (
        <JulotaForm
          inputData={tab.form}
          formDirty={formDirty}
          callbackFunction={(message) => callbackFunction(message)}
        />
      );
      default: return <div>UNRECOGNIZED TAB TYPE</div>;
    }
  };

  return (
    <div className="container-padding-40" style={{ overflowY: 'scroll' }}>
      <h2>
        <CustomLink to={`/hubs/${clientId}/${entityType}s`} style={{ textTransform: 'capitalize' }}>
          <span className="nav-title">{`${entityType}s`}</span>
        </CustomLink>
        <span style={{ padding: '0px 10px' }}>/</span>
        {entityKey}
      </h2>

      <Tabs
        onSelect={handleSelectTab}
        activeKey={activeTab}
        defaultActiveKey={0}
        mountOnEnter
        unmountOnExit
        style={{ marginTop: 40 }}
      >
        {tabs?.map((tab, index) => (
          <Tab
            eventKey={index}
            title={tab.title}
            key={hash(tab.title)}
            style={{ marginTop: 30 }}
          >
            {renderTab(tab)}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
