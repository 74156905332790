import React from 'react';
import { Form } from 'react-bootstrap';

export default function TextArea(props) {
  const { question, value, callbackFunction } = props;

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <Form.Control
        as="textarea"
        rows={4}
        placeholder={question.placeholder || question.label}
        required={question.required}
        name={question.field}
        onChange={callbackFunction}
        disabled={question.disabled}
        value={value}
      />
      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
