import FetchService from 'services/FetchService';

export default class PackagesService {
  static getClientPackages(clientId) {
    return FetchService.fetchData('GET', `packages?client_id=${clientId}`, {});
  }

  static getClientPackageInstallationLog(clientId, packageKey) {
    return FetchService.fetchData('GET', `packages/${packageKey}?client_id=${clientId}`, {});
  }

  static installPackage(clientId, packageKey) {
    return FetchService.fetchData('POST', `packages/${packageKey}/install?client_id=${clientId}`, {});
  }
}
