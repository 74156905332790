const initialState = {
  auth: {},
  verify: false,
  session: {},
};

const authReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        session: action.payload,
      };
    case 'REQUEST_NEW_SESSION':
      return {
        ...state,
        auth: action.payload,
      };
    case 'REQUEST_2FA_SESSION':
      return {
        ...state,
        auth: {
          ...state.auth,
          twfactor: action.payload,
        },
      };
    default:
      return state;
  }
};

export default authReducers;
